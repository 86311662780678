import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as actions from '../../../store/action/timesheetAction';
import * as selectors from '../../../store/selector/timesheetSelector';
import Tooltip from 'react-bootstrap/Tooltip';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Table from '../../layout/form/Table';
import ButtonGroup from '../../layout/form/ButtonGroup';
import SelectField from '../../layout/form/SelectField';
import DatePicker from '../../layout/form/datePicker';
import BootstrapFormControl from 'react-bootstrap/cjs/FormControl';
import common from '../../common';
import moment from 'moment';
import { makeSelectObjectStatusList, makeSelectTenantUser } from '../../../store/selector/selector';
import { makeSelectSummarisedProjectListSuccess } from '../../../store/selector/projectSelector';
import TimesheetTimeDetails from './TimesheetTimeDetails';
import DeleteModal from '../../layout/form/deleteModal';
import CopyModal from './CopyModal';
import ErrorModal from '../../layout/form/errorModal';

const customStyles = {
    control: base => ({
        ...base,
        height: 20,
        minHeight: 32,
        marginBottom: 0,
        marginTop: -20
    })
};


function setWeekFirstDate(weekStartDay) {
    const today = new Date()
    const first = today.getDate() - today.getDay()  + weekStartDay - 1
    const firstDate = new Date(today.setDate(first))
    return firstDate;
}


function setTotal(list_) {
    const list = list_.filter(data => (data.operation !== "D"))
    var day1Total = 0
    var day2Total = 0
    var day3Total = 0
    var day4Total = 0
    var day5Total = 0
    var day6Total = 0
    var day7Total = 0
    var total = 0
    for (let i = 0; i < list.length - 1; i++) {
        const element = list[i];
        day1Total = day1Total + parseFloat(element.day1)
        day2Total = day2Total + parseFloat(element.day2)
        day3Total = day3Total + parseFloat(element.day3)
        day4Total = day4Total + parseFloat(element.day4)
        day5Total = day5Total + parseFloat(element.day5)
        day6Total = day6Total + parseFloat(element.day6)
        day7Total = day7Total + parseFloat(element.day7)
        total = day1Total + day2Total + day3Total + day4Total + day5Total + day6Total + day7Total
    }
    const lastRow = {
        day1Total: day1Total,
        day2Total: day2Total,
        day3Total: day3Total,
        day4Total: day4Total,
        day5Total: day5Total,
        day6Total: day6Total,
        day7Total: day7Total,
        total: total,
        projectActivityId: 0,
        lastRow: true
    }
    return lastRow;
}

function setWeekDays(day) {
    const date = new Date(day)
    const date1 = new Date(day)
    const day2 = date.getDate() + 1
    const date2 = new Date(date.setDate(day2))
    const day3 = date2.getDate() + 1
    const date3 = new Date(date.setDate(day3))
    const day4 = date3.getDate() + 1
    const date4 = new Date(date.setDate(day4))
    const day5 = date4.getDate() + 1
    const date5 = new Date(date.setDate(day5))
    const day6 = date5.getDate() + 1
    const date6 = new Date(date.setDate(day6))
    const day7 = date6.getDate() + 1
    const date7 = new Date(date.setDate(day7))
    const row = {
        day1: moment(date1).format(common.dateFormat),
        day2: moment(date2).format(common.dateFormat),
        day3: moment(date3).format(common.dateFormat),
        day4: moment(date4).format(common.dateFormat),
        day5: moment(date5).format(common.dateFormat),
        day6: moment(date6).format(common.dateFormat),
        day7: moment(date7).format(common.dateFormat),
    }
    return row
}

export class AddTimesheet extends Component {

    constructor(props) {
        super(props);

        this.state = {
            initial: true,
            isChangeData: false,
            weekStartDate: moment(setWeekFirstDate(0)).format(common.dateFormat),
            totalHours: 0,
            id: 0,
            timesheets: [
                {
                    day1: 0,
                    day2: 0,
                    day3: 0,
                    day4: 0,
                    day5: 0,
                    day6: 0,
                    day7: 0,
                    total: 0,
                    operation: "C",
                    id: 0,
                    projectActivityId: 0,
                    day1Details: [],
                    day2Details: [],
                    day3Details: [],
                    day4Details: [],
                    day5Details: [],
                    day6Details: [],
                    day7Details: [],
                    projectId: 0,
                    lastRow: false
                },
                {
                    lastRow: true
                }
            ],
            tempTimesheetsList: [],
            isDeleteTimesheet: false,
            weekDays: setWeekDays(setWeekFirstDate(0)),
            timesheetStatus: '10',
            modifyTimesheet: true,
            isOpenTimesheetTimeDetais: false,
            selectedCell: '',
            timeDetailsTitle: '',
            selectedCellData: '',
            userId: 0,
            selectdDate: '',
            isGetTimesheetDetails: false,
            isDeleteProject: false,
            selectedProject: '',
            isCopyProject: false,
            selectedCopyDate: '',
            hovered: false,
            isValidationError: false,
            errorMassage: ''
        };

    };

    static getDerivedStateFromProps(prevProps, prevState) {
        const { selectedTimesheet, modifyTimesheet, approveTimesheet, createTimesheet, tenantUser, tenantOpenHours } = prevProps;
        const { initial } = prevState
        if (initial && selectedTimesheet && selectedTimesheet.id) {
            var itemList = []
            var isEdit = modifyTimesheet
            var lastRow = {
                lastRow: true
            }
            var dateList = setWeekDays(selectedTimesheet.weekStartDate)

            if (modifyTimesheet && (selectedTimesheet.statusId === '30' || selectedTimesheet.statusId === '20' || selectedTimesheet.statusId === '50' || selectedTimesheet.statusId === '60')) {
                isEdit = false
            }
            if (approveTimesheet && tenantUser && tenantUser.id !== selectedTimesheet.userId) {
                isEdit = false
            }
            else if (approveTimesheet && (selectedTimesheet.statusId === '40' || selectedTimesheet.statusId === '20')) {
                isEdit = true
            }
            if (selectedTimesheet.timeDetails.length !== 0) {
                for (let i = 0; i < selectedTimesheet.timeDetails.length; i++) {
                    const element = selectedTimesheet.timeDetails[i];
                    var selectedDate = new Date(element.workingDate)
                    selectedDate = moment(selectedDate).format(common.dateFormat)
                    if (selectedDate === dateList.day1) {
                        const newRow = {
                            projectActivityId: element.projectActivityId,
                            day1: element.totalHours,
                            day2: 0,
                            day3: 0,
                            day4: 0,
                            day5: 0,
                            day6: 0,
                            day7: 0,
                            total: element.totalHours
                        }
                        itemList.push(newRow)
                    } if (selectedDate === dateList.day2) {
                        const existElement = itemList.find(e => e.projectActivityId === element.projectActivityId)
                        const index = itemList.findIndex(e => e.projectActivityId === element.projectActivityId)
                        if (index > -1) {
                            existElement.day2 = element.totalHours
                            existElement.total = existElement.total + element.totalHours
                            itemList[index] = existElement
                        } else {
                            const newRow = {
                                projectActivityId: element.projectActivityId,
                                day1: 0,
                                day2: element.totalHours,
                                day3: 0,
                                day4: 0,
                                day5: 0,
                                day6: 0,
                                day7: 0,
                                total: element.totalHours
                            }
                            itemList.push(newRow)
                        }
                    } if (selectedDate === dateList.day3) {
                        const existElement = itemList.find(e => e.projectActivityId === element.projectActivityId)
                        const index = itemList.findIndex(e => e.projectActivityId === element.projectActivityId)
                        if (index > -1) {
                            existElement.day3 = element.totalHours
                            existElement.total = existElement.total + element.totalHours
                            itemList[index] = existElement
                        } else {
                            const newRow = {
                                projectActivityId: element.projectActivityId,
                                day1: 0,
                                day2: 0,
                                day3: element.totalHours,
                                day4: 0,
                                day5: 0,
                                day6: 0,
                                day7: 0,
                                total: element.totalHours
                            }
                            itemList.push(newRow)
                        }
                    } if (selectedDate === dateList.day4) {
                        const existElement = itemList.find(e => e.projectActivityId === element.projectActivityId)
                        const index = itemList.findIndex(e => e.projectActivityId === element.projectActivityId)
                        if (index > -1) {
                            existElement.day4 = element.totalHours
                            existElement.total = existElement.total + element.totalHours
                            itemList[index] = existElement
                        } else {
                            const newRow = {
                                projectActivityId: element.projectActivityId,
                                day1: 0,
                                day2: 0,
                                day3: 0,
                                day4: element.totalHours,
                                day5: 0,
                                day6: 0,
                                day7: 0,
                                total: element.totalHours
                            }
                            itemList.push(newRow)
                        }
                    } if (selectedDate === dateList.day5) {
                        const existElement = itemList.find(e => e.projectActivityId === element.projectActivityId)
                        const index = itemList.findIndex(e => e.projectActivityId === element.projectActivityId)
                        if (index > -1) {
                            existElement.day5 = element.totalHours
                            existElement.total = existElement.total + element.totalHours
                            itemList[index] = existElement
                        } else {
                            const newRow = {
                                projectActivityId: element.projectActivityId,
                                day1: 0,
                                day2: 0,
                                day3: 0,
                                day4: 0,
                                day5: element.totalHours,
                                day6: 0,
                                day7: 0,
                                total: element.totalHours
                            }
                            itemList.push(newRow)
                        }
                    } if (selectedDate === dateList.day6) {
                        const existElement = itemList.find(e => e.projectActivityId === element.projectActivityId)
                        const index = itemList.findIndex(e => e.projectActivityId === element.projectActivityId)
                        if (index > -1) {
                            existElement.day6 = element.totalHours
                            existElement.total = existElement.total + element.totalHours
                            itemList[index] = existElement
                        } else {
                            const newRow = {
                                projectActivityId: element.projectActivityId,
                                day1: 0,
                                day2: 0,
                                day3: 0,
                                day4: 0,
                                day5: 0,
                                day6: element.totalHours,
                                day7: 0,
                                total: element.totalHours
                            }
                            itemList.push(newRow)
                        }
                    } if (selectedDate === dateList.day7) {
                        const existElement = itemList.find(e => e.projectActivityId === element.projectActivityId)
                        const index = itemList.findIndex(e => e.projectActivityId === element.projectActivityId)
                        if (index > -1) {
                            existElement.day7 = element.totalHours
                            existElement.total = existElement.total + element.totalHours
                            itemList[index] = existElement
                        } else {
                            const newRow = {
                                projectActivityId: element.projectActivityId,
                                day1: 0,
                                day2: 0,
                                day3: 0,
                                day4: 0,
                                day5: 0,
                                day6: 0,
                                day7: element.totalHours,
                                total: element.totalHours
                            }
                            itemList.push(newRow)
                        }
                    }

                }
            }
            itemList.push(lastRow)
            lastRow = setTotal(itemList)
            itemList[itemList.length - 1] = lastRow
            return {
                initial: false,
                timesheets: itemList,
                timesheetStatus: selectedTimesheet.statusId,
                totalHours: lastRow.total,
                id: selectedTimesheet.id,
                modifyTimesheet: isEdit,
                weekDays: setWeekDays(dateList.day1),
                userName: selectedTimesheet.userName,
                userId: selectedTimesheet.userId,
                weekStartDate: selectedTimesheet.weekStartDate
            }
        }
        else if (initial && selectedTimesheet && selectedTimesheet.id === 0 && tenantOpenHours && tenantOpenHours.weekStartDay && tenantOpenHours.weekStartDay > 0) {
            return {
                initial: false,
                timesheets: [
                    {
                        day1: 0,
                        day2: 0,
                        day3: 0,
                        day4: 0,
                        day5: 0,
                        day6: 0,
                        day7: 0,
                        total: 0,
                        operation: "C",
                        id: 0,
                        projectActivityId: 0,
                        day1Details: [],
                        day2Details: [],
                        day3Details: [],
                        day4Details: [],
                        day5Details: [],
                        day6Details: [],
                        day7Details: [],
                        projectId: 0,
                        lastRow: false
                    },
                    {
                        lastRow: true
                    }
                ],
                tempTimesheetsList: [],
                timesheetStatus: '10',
                totalHours: 0,
                id: 0,
                modifyTimesheet: createTimesheet,
                weekDays: setWeekDays(setWeekFirstDate(tenantOpenHours.weekStartDay)),
                weekStartDate: moment(setWeekFirstDate(tenantOpenHours.weekStartDay)).format(common.dateFormat),
            }
        }
        return null
    }

    componentDidMount() {
        this.props.dispatch(actions.loadTimesheetDetailsSuccess([]))
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            timesheets,
            isDeleteTimesheet,
            tempTimesheetsList,
            isGetTimesheetDetails,
            selectdDate,
            selectedCellData,
            weekStartDate
        } = this.state;
        const { selectedTimesheet, timesheetDetailsSuccess } = this.props;
        if (isDeleteTimesheet) {
            const timeList = timesheets.filter(data => (data.operation === "U" || data.operation === "C" || data.lastRow))
            const tempItemList = timesheets.filter(data => (data.id !== null))
            const lastRow = setTotal(timeList)
            timeList[timeList.length - 1] = lastRow
            this.setState({
                timesheets: timeList,
                isDeleteTimesheet: false,
                tempTimesheetsList: tempTimesheetsList.concat(tempItemList),
                totalHours: lastRow.total
            })
        }
        if (isGetTimesheetDetails) {
            const parameters = {
                id: selectedTimesheet.id,
                date: selectdDate,
                projectActivityId: selectedCellData.original.projectActivityId
            }
            this.props.dispatch(actions.loadTimesheetDetailsRequest(parameters))
            this.setState({
                isGetTimesheetDetails: false
            })
        }
        if (timesheetDetailsSuccess) {
            const userId = sessionStorage.getItem("tenantUserId")
            const parms = {
                weekStartDate: selectedTimesheet.weekStartDate === '0001-01-01T00:00:00' ? weekStartDate : selectedTimesheet.weekStartDate,
                userId: userId
            }
            this.props.dispatch(actions.sendTimesheetDetailsSuccess(false))
            this.props.dispatch(actions.loadSelectedTimesheetSuccess(''))
            this.props.dispatch(actions.loadSelectedTimesheetRequest(parms))
            this.setState({
                initial: true
            })
            this.handleCloseTimeDetails()
        }
    }

    editTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Edit
        </Tooltip>
    );

    copyToolTip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Copy from previous week
        </Tooltip>
    );

    handleDateChange = (date, id) => {
        const { tenantOpenHours } = this.props;
        if (id === 'weekStartDate') {
            const today = new Date(date)
            const first = today.getDate() - today.getDay() + tenantOpenHours.weekStartDay - 1
            const weekMonday = new Date(today.setDate(first))
            const startDate = moment(weekMonday).format(common.dateFormat)
            this.props.dispatch(actions.loadSelectedTimesheetSuccess(''))
            const parms = {
                weekStartDate: startDate,
                userId: this.state.userId
            }
            this.props.dispatch(actions.loadSelectedTimesheetRequest(parms))
            this.setState({
                [id]: startDate,
                weekDays: setWeekDays(weekMonday),
                initial: true
            })
        }
        else {
            this.setState({
                [id]: moment(date).format(common.dateFormat),
                weekDays: setWeekDays(date)
            })
        }
    }

    handleNextDateChange = () => {
        const { weekStartDate } = this.state;
        const { tenantOpenHours } = this.props;
        var nextDate = new Date(weekStartDate)
        const first = nextDate.getDate() + 7 - nextDate.getDay() + tenantOpenHours.weekStartDay - 1
        const weekMonday = new Date(nextDate.setDate(first))
        const startDate = moment(weekMonday).format(common.dateFormat)
        this.props.dispatch(actions.loadSelectedTimesheetSuccess(''))
        const parms = {
            weekStartDate: startDate,
            userId: this.state.userId
        }
        this.props.dispatch(actions.loadSelectedTimesheetRequest(parms))
        this.setState({
            weekStartDate: startDate,
            initial: true,
            weekDays: setWeekDays(weekMonday)
        })
    }

    handlePrevDateChange = () => {
        const { weekStartDate } = this.state;
        const { tenantOpenHours } = this.props;
        var prevDate = new Date(weekStartDate)
        const first = prevDate.getDate() - 7 - prevDate.getDay() + tenantOpenHours.weekStartDay - 1
        const weekMonday = new Date(prevDate.setDate(first))
        const startDate = moment(weekMonday).format(common.dateFormat)
        this.props.dispatch(actions.loadSelectedTimesheetSuccess(''))
        const parms = {
            weekStartDate: startDate,
            userId: this.state.userId
        }
        this.props.dispatch(actions.loadSelectedTimesheetRequest(parms))
        this.setState({
            weekStartDate: startDate,
            initial: true,
            weekDays: setWeekDays(weekMonday)
        })
    }

    setStatusDescription = (data) => {
        const { objectStatusList } = this.props;
        if (objectStatusList && objectStatusList.length && objectStatusList.length !== 0) {
            const desc = objectStatusList.find(select => select.id === data)
            return (desc && desc.description ? desc.description : '')
        }
    }

    addNewRow = () => {
        var { timesheets } = this.state;
        var newRow = {
            day1: 0,
            day2: 0,
            day3: 0,
            day4: 0,
            day5: 0,
            day6: 0,
            day7: 0,
            total: 0,
            operation: "C",
            id: 0,
            projectActivityId: 0,
            day1Details: [],
            day2Details: [],
            day3Details: [],
            day4Details: [],
            day5Details: [],
            day6Details: [],
            day7Details: [],
            projectId: 0
        }
        const size = timesheets.length
        timesheets[size - 1] = newRow
        const lastRow = setTotal(timesheets)
        timesheets[size] = lastRow
        this.setState({
            timesheets,
            totalHours: lastRow.total
        })
    }

    handleRowProject = (id, rowData) => {
        const { timesheets } = this.state;
        const { summarisedProjectList } = this.props;
        const timesheet = timesheets.find(e => e.projectActivityId === id)
        if (timesheet && timesheet.projectActivityId > 0) {
            this.props.dispatch(actions.loadTimesheetListError(common.massages.existProject))
            return
        }
        const project = summarisedProjectList.result.find(e => e.id === id)
        const selectedRow = timesheets[rowData.index]
        selectedRow.projectActivityId = id
        selectedRow.projectId = project.projectId
        timesheets[rowData.index] = selectedRow
        this.setState({ timesheets })
    }

    handleOpenTimeDetails = (e, rowData, title, day, date) => {
        this.setState({
            selectedCellData: rowData,
            selectedCell: day,
            timeDetailsTitle: title,
            selectdDate: date,
            isGetTimesheetDetails: true,
            isOpenTimesheetTimeDetais: true,
        })
    }

    handleCloseTimeDetails = () => {
        this.props.dispatch(actions.loadTimesheetDetailsSuccess([]))
        this.setState({
            isOpenTimesheetTimeDetais: false,
            selectedCell: '',
            selectedCellData: '',
            timeDetailsTitle: '',
            selectdDate: ''
        })
    }

    handleSaveDetails = (hours, list, tempTimeDetailList) => {

        const { selectedTimesheet } = this.props;
        var userId = parseInt(sessionStorage.getItem("tenantUserId"))
        const tempItemList = tempTimeDetailList.filter(data => (data.operation === "D" && data.id > 0))
        const timeDetails = list.concat(tempItemList)
        const newList = timeDetails.filter(data => (data.hours > 0))
        const parameters = {
            id: selectedTimesheet && selectedTimesheet.id ? selectedTimesheet.id : 0,
            timeDetails: newList,
            statusId: selectedTimesheet && selectedTimesheet.statusId ? selectedTimesheet.statusId : "10",
            userId: parseInt(userId)
        }
        if (selectedTimesheet.id > 0) {
            this.props.dispatch(actions.sendUpdateTimesheetRequest(parameters))
        } else
            this.props.dispatch(actions.sendTimesheetDetailsRequest(parameters))
    }

    handleRowDay1 = (selectedRow, hours, total, newList) => {
        selectedRow.day1 = hours
        selectedRow.total = total
        selectedRow.day1Details = newList
        return selectedRow
    }

    handleRowDay2 = (selectedRow, hours, total, newList) => {
        selectedRow.day2 = hours
        selectedRow.total = total
        selectedRow.day2Details = newList
        return selectedRow
    }

    handleRowDay3 = (selectedRow, hours, total, newList) => {
        selectedRow.day3 = hours
        selectedRow.total = total
        selectedRow.day3Details = newList
        return selectedRow
    }

    handleRowDay4 = (selectedRow, hours, total, newList) => {
        selectedRow.day4 = hours
        selectedRow.total = total
        selectedRow.day4Details = newList
        return selectedRow
    }

    handleRowDay5 = (selectedRow, hours, total, newList) => {
        selectedRow.day5 = hours
        selectedRow.total = total
        selectedRow.day5Details = newList
        return selectedRow
    }

    handleRowDay6 = (selectedRow, hours, total, newList) => {
        selectedRow.day6 = hours
        selectedRow.total = total
        selectedRow.day6Details = newList
        return selectedRow
    }

    handleRowDay7 = (selectedRow, hours, total, newList) => {
        selectedRow.day7 = hours
        selectedRow.total = total
        selectedRow.day7Details = newList
        return selectedRow
    }

    handleDelete = (rowData) => {
        const { summarisedProjectList, selectedTimesheet } = this.props;
        if (rowData && rowData.original && rowData.original.projectActivityId > 0 && selectedTimesheet && selectedTimesheet.id > 0) {
            const project = summarisedProjectList.result.find(e => e.id === rowData.original.projectActivityId)
            project.id = rowData.original.projectActivityId
            this.setState({
                isDeleteProject: true,
                selectedProject: project
            })
        }
    }

    handleDeleteModalClose = () => {
        this.setState({
            isDeleteProject: false,
            selectedProject: ''
        })
    }

    handleDeleteProject = () => {
        const { selectedProject } = this.state;
        const { selectedTimesheet } = this.props
        const parameters = {
            id: selectedTimesheet.id,
            projectActivityId: selectedProject.id
        }
        this.props.dispatch(actions.sendTimesheetDetailsDeleteRequest(parameters))
        this.handleDeleteModalClose()
    }

    handleToColumn = (day) => {
        this.setState({
            isCopyProject: true,
            selectedCopyDate: day
        })
    }

    handleCopyModalClose = () => {
        this.setState({
            isCopyProject: false,
            selectedCopyDate: ""
        })
    }

    handleCopyDayDetails = () => {
        this.props.dispatch(actions.loadTimesheetListLoading(true));
        const { selectedCopyDate } = this.state;
        const { selectedTimesheet } = this.props
        const parameters = {
            id: selectedTimesheet.id,
            workingDate: selectedCopyDate
        }
        this.props.dispatch(actions.sendTimesheetDetailsCopyRequest(parameters))
        this.handleCopyModalClose()
    }

    setColumn = (e) => {
        const date = new Date(e)
        const month = date.getMonth() + 1
        const day = date.getDate()
        return (
            <div>
                <div>{common.day[date.getDay()]}</div>
                <div>{day + ' / ' + month}</div>
            </div>
        )
    }

    handleCopyTimesheet = () => {
        const { selectedTimesheet } = this.props
        const userId = parseInt(sessionStorage.getItem("tenantUserId"))
        if (selectedTimesheet && selectedTimesheet.id > 0) {
            this.setState({ isValidationError: true, errorMassage: 'You already have this week timesheet' })
        } else if (selectedTimesheet && selectedTimesheet.id > 0 && selectedTimesheet.userId !== userId) {
            this.setState({ isValidationError: true, errorMassage: `You cannot copy another employee's timesheet.` })
        }
        else {
            this.props.dispatch(actions.loadTimesheetListLoading(true));
            const weekStartDate = new Date(this.state.weekStartDate);
            const date = weekStartDate.getDate() - 7;
            weekStartDate.setDate(date);
            const parameters = {
                userId: userId,
                weekStartDate: moment(weekStartDate).format(common.dateFormat)
            }
            this.props.dispatch(actions.sendTimesheetCopyRequest(parameters))
        }
    }

    errorModalClose = () => {
        this.setState({ isValidationError: false, errorMassage: '' })
    }

    render() {
        const {
            handleClose,
            handleSaveAddNew,
            summarisedProjectList,
            approveTimesheet,
            selectedTimesheet,
            handleSaveStatus,
            handleRejectTimesheet,
            timeTypeList,
            tenantUser,
            timesheetDetails,
            createTimesheet
        } = this.props;

        const {
            timesheets,
            weekDays,
            timesheetStatus,
            modifyTimesheet,
            isOpenTimesheetTimeDetais,
            selectedCell,
            timeDetailsTitle,
            selectedCellData,
            selectdDate,
            isDeleteProject,
            selectedProject,
            isCopyProject,
            selectedCopyDate,
            isValidationError,
            errorMassage
        } = this.state;

        const columns = [{
            Header: 'Project',
            accessor: 'projectActivityId',
            Cell: (rowData) => (
                <div>
                    {rowData.cell.row.original.lastRow ?
                        <div>Total</div> :
                        <SelectField
                            controlId="id"
                            onChange={(e) => this.handleRowProject(e, rowData.cell.row)}
                            options={summarisedProjectList && summarisedProjectList.result && summarisedProjectList.result.length ? summarisedProjectList.result : []}
                            style={customStyles}
                            selectedValue={rowData.cell.row.original.projectActivityId}
                            disabled={!modifyTimesheet}
                        />}
                </div>
            ),
        }, {
            Header: () => (
                <div style={{ textAlign: "right" }}>
                    <div>{this.setColumn(weekDays.day1)}</div>
                </div>
            ),
            accessor: 'day1',
            Cell: ((rowData) => (
                <div>
                    {rowData.cell.row.original.lastRow ?
                        <BootstrapFormControl
                            controlId="day1"
                            type="text"
                            defaultValue={rowData.cell.row.original.day1Total ? parseFloat(rowData.cell.row.original.day1Total).toFixed(2) : 0}
                            size="sm"
                            style={{ textAlign: 'right' }}
                            disabled
                        /> :
                        <div className={rowData.cell.row.original.projectActivityId === 0 ? 'row-cell-disable' : 'row-cell'}>
                            <div class="row-cell-data" onClick={rowData.cell.row.original.projectActivityId === 0 ? '' : (e) => this.handleOpenTimeDetails(e, rowData.cell.row, weekDays.day1, 'day1', weekDays.day1)}>{parseFloat(rowData.cell.row.original.day1).toFixed(2)}</div>
                            <div class="row-cell-icon" onClick={rowData.cell.row.original.projectActivityId === 0 ? '' : (e) => this.handleOpenTimeDetails(e, rowData.cell.row, weekDays.day1, 'day1', weekDays.day1)}>
                                {rowData.cell.row.original.day1 > 0 ?
                                    <ui5-icon class="samples-margin" name="edit" id="action-cell-icon"></ui5-icon> :
                                    <ui5-icon class="samples-margin" name="add" id="action-cell-icon"></ui5-icon>}
                            </div>
                        </div>
                    }
                </div>
            )),
            width: "7%",
        }, {
            Header: () => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <span onClick={modifyTimesheet ? () => this.handleToColumn(weekDays.day1) : ''}><ui5-icon class="samples-margin" name="journey-depart" id="table-header-icon" ></ui5-icon></span>
                    </div>
                    <div>{this.setColumn(weekDays.day2)}</div>
                </div>
            ),
            accessor: 'day2',
            Cell: ((rowData) => (
                <div>
                    {rowData.cell.row.original.lastRow ?
                        <BootstrapFormControl
                            controlId="day2"
                            type="text"
                            defaultValue={rowData.cell.row.original.day2Total ? parseFloat(rowData.cell.row.original.day2Total).toFixed(2) : 0}
                            size="sm"
                            style={{ textAlign: 'right' }}
                            disabled
                        /> :
                        <div className={rowData.cell.row.original.projectActivityId === 0 ? 'row-cell-disable' : 'row-cell'}>
                            <div class="row-cell-data" onClick={rowData.cell.row.original.projectActivityId === 0 ? '' : (e) => this.handleOpenTimeDetails(e, rowData.cell.row, weekDays.day2, 'day2', weekDays.day2)}>{parseFloat(rowData.cell.row.original.day2).toFixed(2)}</div>
                            <div class="row-cell-icon" onClick={rowData.cell.row.original.projectActivityId === 0 ? '' : (e) => this.handleOpenTimeDetails(e, rowData.cell.row, weekDays.day2, 'day2', weekDays.day2)}>
                                {rowData.cell.row.original.day2 > 0 ?
                                    <ui5-icon class="samples-margin" name="edit" id="action-cell-icon"></ui5-icon> :
                                    <ui5-icon class="samples-margin" name="add" id="action-cell-icon"></ui5-icon>}
                            </div>
                        </div>
                    }
                </div>
            )),
            width: "7%",
        }, {
            Header: () => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <span onClick={modifyTimesheet ? () => this.handleToColumn(weekDays.day2) : ''}><ui5-icon class="samples-margin" name="journey-depart" id="table-header-icon" ></ui5-icon></span>
                    </div>
                    <div>{this.setColumn(weekDays.day3)}</div>
                </div>
            ),
            accessor: 'day3',
            Cell: ((rowData) => (
                <div>
                    {rowData.cell.row.original.lastRow ?
                        <BootstrapFormControl
                            controlId="day3"
                            type="text"
                            defaultValue={rowData.cell.row.original.day3Total ? parseFloat(rowData.cell.row.original.day3Total).toFixed(2) : 0}
                            size="sm"
                            style={{ textAlign: 'right' }}
                            disabled
                        /> :
                        <div className={rowData.cell.row.original.projectActivityId === 0 ? 'row-cell-disable' : 'row-cell'}>
                            <div class="row-cell-data" onClick={rowData.cell.row.original.projectActivityId === 0 ? '' : (e) => this.handleOpenTimeDetails(e, rowData.cell.row, weekDays.day3, 'day3', weekDays.day3)}>{parseFloat(rowData.cell.row.original.day3).toFixed(2)}</div>
                            <div class="row-cell-icon" onClick={rowData.cell.row.original.projectActivityId === 0 ? '' : (e) => this.handleOpenTimeDetails(e, rowData.cell.row, weekDays.day3, 'day3', weekDays.day3)}>
                                {rowData.cell.row.original.day3 > 0 ?
                                    <ui5-icon class="samples-margin" name="edit" id="action-cell-icon"></ui5-icon> :
                                    <ui5-icon class="samples-margin" name="add" id="action-cell-icon"></ui5-icon>}
                            </div>
                        </div>
                    }
                </div>
            )),
            width: "7%",
        }, {
            Header: () => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <span onClick={modifyTimesheet ? () => this.handleToColumn(weekDays.day3) : ''}><ui5-icon class="samples-margin" name="journey-depart" id="table-header-icon" ></ui5-icon></span>
                    </div>
                    <div>{this.setColumn(weekDays.day4)}</div>
                </div>
            ),
            accessor: 'day4',
            Cell: ((rowData) => (
                <div>
                    {rowData.cell.row.original.lastRow ?
                        <BootstrapFormControl
                            controlId="day4"
                            type="text"
                            defaultValue={rowData.cell.row.original.day4Total ? parseFloat(rowData.cell.row.original.day4Total).toFixed(2) : 0}
                            size="sm"
                            style={{ textAlign: 'right' }}
                            disabled
                        /> :
                        <div className={rowData.cell.row.original.projectActivityId === 0 ? 'row-cell-disable' : 'row-cell'}>
                            <div class="row-cell-data" onClick={rowData.cell.row.original.projectActivityId === 0 ? '' : (e) => this.handleOpenTimeDetails(e, rowData.cell.row, weekDays.day4, 'day4', weekDays.day4)}>{parseFloat(rowData.cell.row.original.day4).toFixed(2)}</div>
                            <div class="row-cell-icon" onClick={rowData.cell.row.original.projectActivityId === 0 ? '' : (e) => this.handleOpenTimeDetails(e, rowData.cell.row, weekDays.day4, 'day4', weekDays.day4)}>
                                {rowData.cell.row.original.day4 > 0 ?
                                    <ui5-icon class="samples-margin" name="edit" id="action-cell-icon"></ui5-icon> :
                                    <ui5-icon class="samples-margin" name="add" id="action-cell-icon"></ui5-icon>}
                            </div>
                        </div>
                    }
                </div>
            )),
            width: "7%",
        }, {
            Header: () => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <span onClick={modifyTimesheet ? () => this.handleToColumn(weekDays.day4) : ''}><ui5-icon class="samples-margin" name="journey-depart" id="table-header-icon" ></ui5-icon></span>
                    </div>
                    <div>{this.setColumn(weekDays.day5)}</div>
                </div>
            ),
            accessor: 'day5',
            Cell: ((rowData) => (
                <div>
                    {rowData.cell.row.original.lastRow ?
                        <BootstrapFormControl
                            controlId="day5"
                            type="text"
                            defaultValue={rowData.cell.row.original.day5Total ? parseFloat(rowData.cell.row.original.day5Total).toFixed(2) : 0}
                            size="sm"
                            style={{ textAlign: 'right' }}
                            disabled
                        /> :
                        <div className={rowData.cell.row.original.projectActivityId === 0 ? 'row-cell-disable' : 'row-cell'}>
                            <div class="row-cell-data" onClick={rowData.cell.row.original.projectActivityId === 0 ? '' : (e) => this.handleOpenTimeDetails(e, rowData.cell.row, weekDays.day5, 'day5', weekDays.day5)}>{parseFloat(rowData.cell.row.original.day5).toFixed(2)}</div>
                            <div class="row-cell-icon" onClick={rowData.cell.row.original.projectActivityId === 0 ? '' : (e) => this.handleOpenTimeDetails(e, rowData.cell.row, weekDays.day5, 'day5', weekDays.day5)}>
                                {rowData.cell.row.original.day5 > 0 ?
                                    <ui5-icon class="samples-margin" name="edit" id="action-cell-icon"></ui5-icon> :
                                    <ui5-icon class="samples-margin" name="add" id="action-cell-icon"></ui5-icon>}
                            </div>
                        </div>
                    }
                </div>
            )),
            width: "7%",
        }, {
            Header: () => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <span onClick={modifyTimesheet ? () => this.handleToColumn(weekDays.day5) : ''}><ui5-icon class="samples-margin" name="journey-depart" id="table-header-icon" ></ui5-icon></span>
                    </div>
                    <div>{this.setColumn(weekDays.day6)}</div>
                </div>
            ),
            accessor: 'day6',
            Cell: ((rowData) => (
                <div>
                    {rowData.cell.row.original.lastRow ?
                        <BootstrapFormControl
                            controlId="day6"
                            type="text"
                            defaultValue={rowData.cell.row.original.day6Total ? parseFloat(rowData.cell.row.original.day6Total).toFixed(2) : 0}
                            size="sm"
                            style={{ textAlign: 'right' }}
                            disabled
                        /> :
                        <div className={rowData.cell.row.original.projectActivityId === 0 ? 'row-cell-disable' : 'row-cell'}>
                            <div class="row-cell-data" onClick={rowData.cell.row.original.projectActivityId === 0 ? '' : (e) => this.handleOpenTimeDetails(e, rowData.cell.row, weekDays.day6, 'day6', weekDays.day6)}>{parseFloat(rowData.cell.row.original.day6).toFixed(2)}</div>
                            <div class="row-cell-icon" onClick={rowData.cell.row.original.projectActivityId === 0 ? '' : (e) => this.handleOpenTimeDetails(e, rowData.cell.row, weekDays.day6, 'day6', weekDays.day6)}>
                                {rowData.cell.row.original.day6 > 0 ?
                                    <ui5-icon class="samples-margin" name="edit" id="action-cell-icon"></ui5-icon> :
                                    <ui5-icon class="samples-margin" name="add" id="action-cell-icon"></ui5-icon>}
                            </div>
                        </div>
                    }
                </div>
            )),
            width: "7%",
        }, {
            Header: () => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <span onClick={modifyTimesheet ? () => this.handleToColumn(weekDays.day6) : ''}><ui5-icon class="samples-margin" name="journey-depart" id="table-header-icon" ></ui5-icon></span>
                    </div>
                    <div>{this.setColumn(weekDays.day7)}</div>
                </div>
            ),
            accessor: 'day7',
            Cell: ((rowData) => (
                <div>
                    {rowData.cell.row.original.lastRow ?
                        <BootstrapFormControl
                            controlId="day7"
                            type="text"
                            defaultValue={rowData.cell.row.original.day7Total ? parseFloat(rowData.cell.row.original.day7Total).toFixed(2) : 0}
                            size="sm"
                            style={{ textAlign: 'right' }}
                            disabled
                        /> :
                        <div className={rowData.cell.row.original.projectActivityId === 0 ? 'row-cell-disable' : 'row-cell'}>
                            <div class="row-cell-data" onClick={rowData.cell.row.original.projectActivityId === 0 ? '' : (e) => this.handleOpenTimeDetails(e, rowData.cell.row, weekDays.day7, 'day7', weekDays.day7)}>{parseFloat(rowData.cell.row.original.day7).toFixed(2)}</div>
                            <div class="row-cell-icon" onClick={rowData.cell.row.original.projectActivityId === 0 ? '' : (e) => this.handleOpenTimeDetails(e, rowData.cell.row, weekDays.day7, 'day7', weekDays.day7)}>
                                {rowData.cell.row.original.day7 > 0 ?
                                    <ui5-icon class="samples-margin" name="edit" id="action-cell-icon"></ui5-icon> :
                                    <ui5-icon class="samples-margin" name="add" id="action-cell-icon"></ui5-icon>}
                            </div>
                        </div>
                    }
                </div>
            )),
            width: "7%",
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Total</div>),
            accessor: 'total',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="total"
                        type="text"
                        defaultValue={rowData.cell.row.original.total ? parseFloat(rowData.cell.row.original.total).toFixed(2) : 0}
                        size="sm"
                        onBlur={(e) => this.handleRowTotal(e, rowData.cell.row)}
                        style={{ textAlign: 'right' }}
                        disabled
                    />
                </div>
            )),
            width: "8%",
        }, {
            Header: 'Action',
            width: '8%',
            Cell: ((rowData) => (
                <div>
                    {rowData.cell.row.original.lastRow ?
                        <span style={{ display: 'flex', justifyContent: 'center' }}>
                            <span onClick={modifyTimesheet ? () => this.addNewRow() : ''}><ui5-icon class="samples-margin" name="sys-add" id={modifyTimesheet ? "table-icon" : "table-icon-disabled"} ></ui5-icon></span>
                        </span> :
                        <span style={{ display: 'flex', justifyContent: 'center' }}>
                            <span onClick={modifyTimesheet ? () => this.handleDelete(rowData.cell.row) : ''}><ui5-icon class="samples-margin" name="delete" id={modifyTimesheet ? "table-icon" : "table-icon-disabled"}></ui5-icon></span>
                        </span>}
                </div>
            )),
            accessor: 'action'
        }]

        return (
            <div>
                {isOpenTimesheetTimeDetais &&
                    <TimesheetTimeDetails
                        show={isOpenTimesheetTimeDetais}
                        handleClose={this.handleCloseTimeDetails}
                        handleSaveDetails={this.handleSaveDetails}
                        title={timeDetailsTitle}
                        selectedCell={selectedCell}
                        selectedCellData={selectedCellData}
                        modifyTimesheet={modifyTimesheet}
                        timeTypeList={timeTypeList}
                        selectdDate={selectdDate}
                        timesheetDetails={timesheetDetails}
                        summarisedProjectList={summarisedProjectList}
                    />
                }
                {isDeleteProject &&
                    <DeleteModal
                        show={isDeleteProject}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteProject}
                        title={"Project - " + selectedProject.projectName}
                    />}
                {(isValidationError) &&
                    <ErrorModal
                        show={true}
                        massage={errorMassage}
                        handleClose={this.errorModalClose}
                    />}
                {isCopyProject &&
                    <CopyModal
                        show={isCopyProject}
                        handleCopyModalClose={this.handleCopyModalClose}
                        handleCopy={this.handleCopyDayDetails}
                        title={selectedCopyDate}
                    />}
                <div className='content-body'>
                    <div className="filter-header">
                        <div className="header-pages">
                            <span>Timesheet {approveTimesheet && selectedTimesheet.userName ? " - " + selectedTimesheet.userName : ''}</span>
                        </div>
                        <div className="btn-close" onClick={handleClose} >
                            <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                        </div>
                    </div>
                    <div className="content-header" style={{ margin: '-50px 0 15px 0' }}></div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <h6>Week Start</h6>
                            </div>
                            <div style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => this.handlePrevDateChange()}>
                                <ui5-icon class="samples-margin" name="media-reverse" id="header-icon"></ui5-icon>
                            </div>
                            <div style={{ margin: '-25px 0 0 10px' }}>
                                <DatePicker
                                    controlId="weekStartDate"
                                    onChange={(e) => this.handleDateChange(e, "weekStartDate")}
                                    value={this.state.weekStartDate === null ? '' : new Date(this.state.weekStartDate)}
                                // disabled={!modifyTimesheet}
                                // format={dateFormat}
                                />
                            </div>
                            <div onClick={() => this.handleNextDateChange()} style={{ cursor: 'pointer' }}>
                                <ui5-icon class="samples-margin" name="media-play" id="header-icon"></ui5-icon>
                            </div>
                            <div style={{ margin: '0 0 5px 10px' }}>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={this.copyToolTip}
                                >
                                    <span className={createTimesheet ? "header-icon" : "header-icon-disabled"} style={{ padding: '5px 0' }} onClick={createTimesheet ? () => this.handleCopyTimesheet() : ''}>
                                        <ui5-icon class="samples-margin" name="journey-depart" id="header-icon"></ui5-icon>
                                    </span>
                                </OverlayTrigger>
                                <span>Copy from previous week</span>
                            </div>
                        </div>
                        <div className="header-btn">
                            <div style={{ display: 'flex' }}>
                                <div>Status : </div>
                                <div style={{ marginLeft: '5px' }}>
                                    {timesheetStatus === "10" ?
                                        <div style={{ backgroundColor: '#ebd936', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(timesheetStatus)}</div> :
                                        timesheetStatus === "60" ?
                                            <div style={{ backgroundColor: '#36eb69', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(timesheetStatus)}</div> :
                                            timesheetStatus === "30" ?
                                                <div style={{ backgroundColor: '#6da4e4', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(timesheetStatus)}</div> :
                                                timesheetStatus === "40" ?
                                                    <div style={{ backgroundColor: '#eb7f36', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(timesheetStatus)}</div> :
                                                    timesheetStatus === "20" ?
                                                        <div style={{ backgroundColor: '#a2eb36', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(timesheetStatus)}</div> :
                                                        <div style={{ backgroundColor: '#ff6384', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(timesheetStatus)}</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile-content" style={{ marginTop: '-20px' }}>
                        {/* <div className="page_header"> */}
                        {/* <div className="page_header-title">
                            <span>Timesheets</span>
                        </div> */}
                        <div style={{ marginBottom: '10px' }}>
                            <Table
                                columns={columns}
                                data={timesheets}
                            />

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {approveTimesheet && selectedTimesheet.statusId === "30" ?
                                <ButtonGroup
                                    primaryBtnClick={() => handleSaveStatus(this.state, "20")}
                                    primaryBtnIcon='save'
                                    primaryBtnText='Approve'
                                    primaryBtnVariant="outline-primary"
                                    primaryBtnDisabled={!approveTimesheet || selectedTimesheet.statusId !== '30' || tenantUser.id === selectedTimesheet.userId || selectedTimesheet.statusId === '20'}
                                    secondaryBtnClick={() => handleRejectTimesheet(this.state, "40")}
                                    secondaryBtnIcon='save'
                                    secondaryBtnText='Reject'
                                    secondaryBtnVariant="outline-primary"
                                    secondaryBtnDisabled={!approveTimesheet || selectedTimesheet.statusId !== '30' || tenantUser.id === selectedTimesheet.userId || selectedTimesheet.statusId === '20'}
                                /> :
                                <ButtonGroup
                                    // primaryBtnClick={() => handleSaveAddNew(this.state, "10")}
                                    primaryBtnIcon='save'
                                    primaryBtnText='Save'
                                    primaryBtnMaxWidth="180px"
                                    primaryBtnVariant="outline-primary"
                                    // primaryBtnDisabled={true}
                                    secondaryBtnClick={() => handleSaveAddNew(this.state, "30")}
                                    secondaryBtnIcon='save'
                                    secondaryBtnText='Submit'
                                    secondaryBtnVariant="outline-primary"
                                    secondaryBtnDisabled={!modifyTimesheet || selectedTimesheet.statusId === '20'}
                                />}
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        )
    }
}

AddTimesheet.propTypes = {
    isModalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
    summarisedProjectList: makeSelectSummarisedProjectListSuccess(),
    selectedTimesheet: selectors.makeSelectSelectedTimesheetSuccess(),
    objectStatusList: makeSelectObjectStatusList(),
    timeTypeList: selectors.makeSelectTimeTypeSuccess(),
    tenantUser: makeSelectTenantUser(),
    timesheetDetails: selectors.makeSelectTimesheetDetailsSuccess(),
    timesheetDetailsSuccess: selectors.makeSelectUpdateTimesheetDetailsSuccess(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);
export default (compose(withConnect)(AddTimesheet));