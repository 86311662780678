import React from 'react';
import TimePicker from 'react-bootstrap-time-picker';
// import 'react-bootstrap-time-picker/dist/TimePicker.css'
import BootstrapFormLabel from 'react-bootstrap/cjs/FormLabel';
import BootstrapFormGroup from 'react-bootstrap/cjs/FormGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import './form.css';

const timePicker = ({
    controlId,
    text,
    // type,
    // as,
    // row,
    onChange,
    value,
    format,
    required,
    ...props
}) => (
    <div style={{fontSize:'14px',fontWeight: 500}}>
        <BootstrapFormGroup controlId={controlId}>
            {text && <div><BootstrapFormLabel>{text} {required ? <span style={{color:'#FF5733'}}>*</span> : ''}</BootstrapFormLabel></div>}
                <TimePicker 
                {...props}
                    className="datePicker"
                    onChange={onChange} 
                    value={value} 
                    disableClock
                    format="HH:mm"
                    hourPlaceholder='hh'
                    minutePlaceholder='mm'
                    amPmAriaLabel="Select AM/PM"
                    size="sm"
                    start="06:00"
                    end="21:00" 
                    step={15}
                />
        </BootstrapFormGroup>
    </div>
);

timePicker.propTypes = {
    controlId: PropTypes.any,
    text: PropTypes.any,
    required: PropTypes.any,
    placeholder: PropTypes.any,
    // as: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.any,
    format: PropTypes.any
}

export default timePicker;
