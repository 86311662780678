import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function addNewTimesheetRequest(data){
    const parameters = JSON.stringify(data)
      return axios.post(baseURL+'timesheet', parameters, {
          headers: {
              'Content-Type': 'application/json',
              'authorization' : sessionStorage.getItem("accessToken")
          }
      })
}

export function getTimesheetList(parameters){
    return axios.get(baseURL+'timesheet?pageSize='+parameters.pageSize+'&pageNumber='+parameters.pageNumber+'&status='+parameters.status+'&toDate='+parameters.toDate+'&fromDate='+parameters.fromDate, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function updateTimesheetRequest(data){
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL+'timesheet/'+data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function deleteTimesheetRequest(id){
    return axios.delete(baseURL+'timesheet/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getSelectedTimesheet(parameters){
    return axios.get(baseURL+'timesheet/weekStartDay?UserId='+parameters.userId+'&Date='+parameters.weekStartDate, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function updateTimesheetStatusRequest(data){
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL+'timesheet/status/'+data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function exportTimesheet(parameters){
    return axios.get(baseURL+'timesheet/report?startDate='+parameters.startDate+'&endDate='+parameters.endDate, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getTimeTypeList(){
    return axios.get(baseURL+'timeType', {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getTimesheetDetailList(parameters){
    return axios.get(baseURL+'timesheet/details/'+parameters.id + '?projectActivityId='+parameters.projectActivityId+'&Date='+parameters.date, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function deleteTimesheetDetailList(parameters){
    return axios.delete(baseURL+'timesheet/details/'+parameters.id + '?projectActivityId='+parameters.projectActivityId, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function sendCopyTimesheetDetailsRequest(data){
    const parameters = JSON.stringify(data)
    return axios.patch(baseURL+'timesheet/copyDetails/'+data.id, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function sendCopyTimesheetRequest(parameters){
    return axios.post(baseURL+'timesheet/copy?UserId='+parameters.userId+'&Date='+parameters.weekStartDate, null, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}

export function getInvoiceTimesheetDetailList(parameters){
    return axios.get(baseURL+'timesheet/details/project?projectId='+parameters.projectId+'&Date='+parameters.date, {
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        }
    })
}