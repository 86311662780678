import { fromJS } from 'immutable';
import {
  LOAD_TIMESHEET_LIST_ERROR,
  LOAD_TIMESHEET_LIST_LOADING,
  LOAD_TIMESHEET_LIST_SUCCESS,
  LOAD_SELECTED_TIMESHEET_SUCCESS,
  SEND_TIMESHEET_SUCCESS,
  SAVE_IS_TIMESHEET_OPEN_STATUS,
  LOAD_TIME_TYPE_SUCCESS,
  LOAD_TIMESHEET_DETAILS_SUCCESS,
  SEND_TIMESHEET_DETAILS_SUCCESS,
} from '../constant/timesheetConstant';

export const initialState = fromJS({
    isTimesheetModalOpen:false,
    timesheetList:[],
    isTimesheetListLoading:false,
    timesheetListError:null,
    timesheetSuccess:null,
    selectedTimesheet:'',
    timeTypeList: [],
    timesheetDetails:[],
    timesheetDetailsSuccess: false
});

function timesheetReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_TIMESHEET_OPEN_STATUS:
      return state.set('isTimesheetModalOpen', action.open)
    case LOAD_TIMESHEET_LIST_SUCCESS:
      return state.set('timesheetList', action.payload)
    case LOAD_TIMESHEET_LIST_LOADING:
      return state.set('isTimesheetListLoading', action.payload)
    case LOAD_TIMESHEET_LIST_ERROR:
      return state.set('timesheetListError', action.payload)
    case SEND_TIMESHEET_SUCCESS:
      return state.set('timesheetSuccess', action.payload)
    case LOAD_SELECTED_TIMESHEET_SUCCESS:
      return state.set('selectedTimesheet', action.payload)
    case LOAD_TIME_TYPE_SUCCESS:
      return state.set('timeTypeList', action.payload)
    case LOAD_TIMESHEET_DETAILS_SUCCESS:
      return state.set('timesheetDetails', action.payload)
    case SEND_TIMESHEET_DETAILS_SUCCESS:
      return state.set('timesheetDetailsSuccess', action.payload)
    default:
      return state;
  }
}

export default timesheetReducer;
