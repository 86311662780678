import Cookies from 'js-cookie';

const common = 
    {
        userRole : [
            { id: 'admin', description: 'Admin User' },
            { id: 'general', description: 'General User' }],
        status : [
            { id: 0, description: 'Inactive' },
            { id: 1, description: 'Active' }
        ],
        discountType : [
            { id: '0', value:'0', description: 'Discount Percentage' },
            { id: '1', value:'1', description: 'Discount Value' }
        ],
        headers: {
            'Content-Type': 'application/json',
            'authorization' : sessionStorage.getItem("accessToken")
        },
        emailObjectType : [
            { id: 'BOOKING', description: 'Booking' },
            { id: 'VEHICLE', description: 'Service Reminder' },
            { id: 'REGISTRATION', description: 'Registration' },
            { id: 'SURVEY', description: 'Survey' },
            { id: 'PURCHASEORDER', description: 'Purchase Order' },
            { id: 'JOBCARD_IMAGE', description: 'Job Card Image' },
            { id: 'QUOTATION_IMAGE', description: 'Quotation Image' }
        ],
        maxLengths:{
            projectId: 50,
            projectName: 30,
            employeeId: 6,
            employeeFirstName:25,
            employeeLastName:45,
            employeeEmail:250,
            employeeMobile:50,
            roleName:20,
            projectCode:10,
            purchaseOrderNo: 11
        },
        objectId: {
            jobCard: 'JOBCARD',
            invoice: 'INVOICE',
            parts: 'PARTS',
            repair: 'REPAIR',
            customer: 'CUSTOMER',
            vehicle: 'VEHICLE',
            jobCardTemplate: 'JOBCARD_TEMPLATE',
            user: 'USER',
            booking: 'BOOKING',
            serviceShedule: 'SERVICE_SCHEDULE',
            supplier: 'SUPPLIER',
            purchasing: 'PURCHASEORDER',
            products: 'PRODUCTS',
            employee: 'EMPLOYEE',
            timesheet: 'TIMESHEET',
            tenant: 'TENANT',
            quotation: 'QUOTATION',
            customerNotification: 'NOTIFICATION',
            emailTemplate: 'EMAIL_TEMPLATE',
            smsTemplate: 'SMS_TEMPLATE',
            userRole: 'USERROLE',
            stockDocument: 'STOCK_DOCUMENT',
            project:'PROJECT',
            intergrted: 'INTEGRATE',
            appInvoice: 'APP_INVOICE',
            inspection: 'INSPECTION',
            inspectionTemplate: 'INSPEC_TEMPLATE',
            tenantSupport: 'SUPPORT_REQUEST',
            vehicleType: 'VEHICLE_TYPE',
            model: 'MODEL',
            dashboard: 'DASHBOARD',
            title: 'TITLE',
            make: 'MAKE',
            product: 'PRODUCT',
            service: 'SERVICE',
            productCategory: 'PRODUCT_CATEGORY',
            vehicleHistory: 'VEHICLE_HISTORY',
            notificationType: 'NOTIFIC_TYPE',
            salesReport: 'SALES_REPORT',
            xero: 'XERO',
            myob: 'MYOB',
            quickBooks: 'QUICK_BOOKS',
            accounting: 'ACCOUNTING',
            marketing: 'MARKETING',
            mailChimp: 'MAILCHIMP',
            stock: 'STOCK',
            paymentMethod: 'PAYMENT_METHOD',
            emailConfig: 'EMAIL_CONFIG',
            notification: 'NOTIFICATION',
            appBilling: 'APP_BILLING',
            analytics: 'ANALYTICS',
            supportRequest: 'SUPPORT_REQUEST',
            masterData: 'MASTER_DATA',
            template: 'TEMPLATE',
            userManagement: 'USER_MANAGEMENT',
            integration: 'INTEGRATION',
            communication: 'COMMUNICATION',
            setting: 'SETTING',
            report: 'REPORT'
        },
        operationId: {
            create: 'CREATE',
            modify: 'MODIFY',
            delete: 'DELETE',
            approve: 'APPROVE',
            download: 'EXPORT',
            display: 'DISPLAY'
        },
        userStatus:{
            active:"10",
            inActive:"50"
        },
        error:"Something went wrong, please try again",
        serviceRemindersMessage : "Service reminders was schedule to sent",
        invoiceEmailMessage: "Email was sent",
        purchasingEmailMessage: "Email was sent",
        purchasingAddAndPrintError: "Record successfully created.Can't print !",
        purchasingAddAndEmailError: "Record successfully created.Can't send email !",
        dateFormat:"YYYY-MM-DD",
        dateTimeFormat:"YYYY-MM-DD HH:mm",
        timeFormat:'HH:mm',
        bookingRemindersMessage : "Booking reminders was schedule to sent",
        groupBy:[
            { id: 1, description: 'Invoice No' },
            { id: 2, description: 'Date' },
            { id: 3, description: 'Month' },
            { id: 4, description: 'Year' }
        ],
        massages:{
            emailMassage:"Please enter your email address",
            requiredFieldMassage:"Missing required Field",
            customerDeleteMassage:"This customer can't delete",
            supplierDeleteMassage:"This supplier can't delete",
            requiredUserRoleMassage:"Please add user role",
            requiredRoleNameMassage:"Missing role name",
            requiredRoleOperationMassage:"Please add user role access",
            existProject : "This project has already been used",
            objectExistMassage: "This Object already added",
        },
        deleteMassages : [
            { id: 6001, description: 'This Customer has Vehicles' },
            { id: 6002, description: 'This Customer has Invoice' },
            { id: 6003, description: 'This Customer has Quotation' },
            { id: 6004, description: 'This Customer has Jobcard' },
            { id: 6005, description: 'This Vehicle has Jobcard' },
            { id: 6006, description: 'This Vehicle has Invoice' },
            { id: 6007, description: 'This Vehicle has Quotation' },
            { id: 6009, description: 'This Jobcard has Quotation' },
            { id: 6010, description: 'This Jobcard has Invoice' },
        ],
        lineChartDetails :{
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul','Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            chartColor_1: '#ff6384',
            chartColor_2: '#36a2eb',
            chartColor_3: '#FFCD56',
            chartColor_4: '#2EFD4A',
            chartColor_5: '#ec0032',
            viewOption:[
                { id: 1, description: 'Last 3 Years' },
                { id: 2, description: 'Last 5 Years' },
            ]
        },
        pieChartDetails : {
            labels: ['5 Star', '4 Star', '3 Star','2 Star', '1 Star'],
            backgroundColor: [
                '#2EFD4A',
                '#36a2eb',
                '#ffff00',
                '#f5a423',
                '#ec0032'
            ],
        },
        graphicalCalender:{
            confirmedColor:'#36a2eb',
            unConfirmColor: '#ff6384',
            monthView:'month',
            weekView:'week',
            dayView:'day'
        },
        tenantDefault:{
            model:"COMMON_MODEL",
        },
        accessList : { 
            createProject: false,
            deleteProject: false,
            modifyProject: false,
            createTimesheet: false,
            deleteTimesheet: false,
            modifyTimesheet: false,
            approveTimesheet: false,
            downloadTimesheet: false,
            createUser: false,
            deleteUser: false,
            modifyUser: false,
        },
        accounting: {
            quickBooks: "10",
            xero: "20",
            myob: "30"
        },
        day :["Sun","Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    }


export default common

export function refeshTokenset(accessToken) {
    // console.log('refeshTokenset')
    if(accessToken === null){
        return
    }else
        sessionStorage.setItem('accessToken', accessToken);
}

export function invalidToken(){
    // console.log('invalidToken')
    Cookies.remove("user")
    sessionStorage.removeItem('accessToken')
    localStorage.removeItem('user')
    window.location.reload('/');
}

export function numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function genarateVehicleAddObject(selectedVehicle, id) {
    const parameters = {
        isExistVehicle: selectedVehicle.isExistVehicle,
        id: '',
        vehicleTypeId: '',
        vehicleRegistrationNumber: selectedVehicle.vehicleRegistrationNumber ,
        complianceDate:'',
        builtDate:'',
        lastServicedDate: '',
        serviceDueDate: '',
        serviceDueMillage: '',
        customerId: id,
        colour: '',
        vehicleIdentificationNo: '',
        makeId: selectedVehicle.makeId,
        modelId: selectedVehicle.modelId,
        modelVariance: '',
        statusId: selectedVehicle.statusId,
        engineNo: '',
        transmissionTypeId: '',
        fuelTypeId: '',
        tyreSize: '',
        customerField1: '',
        customerField2: '',
        customerField3: '',
        serviceRemindersSent: '',
        registrationDueDate:'',
        numberOfCylinders: '',
        lastServicedMillage: '',
        notes: '',
    }
    return parameters;
}

export function calculateTotal(tax, item, isPriceInclusiveTax, discountType, discountTypeValue) {
    const taxRate = (tax / 100)
    if (item.length === 0) {
        const parms = {
            subTotal: 0,
            tax: 0,
            total: 0,
            gstCalValue: 0,
            discountValue: 0
        }
        return parms;
    }
    var newSubtotal = 0
    var taxValue = 0
    var discount = 0
    for (let i = 0; i < item.length; i++) {
        const element = item[i];
        newSubtotal = newSubtotal + (element.subTotal ? element.subTotal : 0)
    }
    if (newSubtotal === 0) {
        const parms = {
            subTotal: 0,
            tax: 0,
            total: 0,
            gstCalValue: 0,
            discountValue: 0
        }
        return parms;
    }
    if (isPriceInclusiveTax) {
        const exclusiveTaxPrice = (newSubtotal * 100 / (100 + taxRate * 100))
        if (discountType === '0') {
            discount = exclusiveTaxPrice * (discountTypeValue / 100)
        } else if (discountType === '1') {
            discount = parseInt(discountTypeValue)
        }
        const gstCalValue_ = exclusiveTaxPrice - discount
        taxValue = gstCalValue_ * (taxRate)
        const taxValue_ = Math.round((taxValue + Number.EPSILON) * 100) / 100
        const discount_ = Math.round((discount + Number.EPSILON) * 100) / 100
        const newSubtotal_ = Math.round((newSubtotal + Number.EPSILON) * 100) / 100
        const total_ = Math.round(((exclusiveTaxPrice - discount_ + taxValue_) + Number.EPSILON) * 100) / 100
        const parms = {
            subTotal: newSubtotal_,
            discountValue: discount_,
            tax: taxValue_,
            total: total_,
            gstCalValue: Math.round((gstCalValue_ + Number.EPSILON) * 100) / 100
        }
        return parms;
    } else if (!isPriceInclusiveTax) {
        if (discountType === '0') {
            discount = newSubtotal * (discountTypeValue / 100)
        } else if (discountType === '1') {
            discount = parseInt(discountTypeValue)
        }
        const gstCalValue_ = newSubtotal - discount
        taxValue = gstCalValue_ * (taxRate)
        const taxValue_ = Math.round((taxValue + Number.EPSILON) * 100) / 100
        const discount_ = Math.round((discount + Number.EPSILON) * 100) / 100
        const newSubtotal_ = Math.round((newSubtotal + Number.EPSILON) * 100) / 100
        const total_ = newSubtotal_ - discount_ + taxValue_
        const parms = {
            subTotal: newSubtotal_,
            discountValue: discount_,
            tax: taxValue_,
            total: total_,
            gstCalValue: Math.round((gstCalValue_ + Number.EPSILON) * 100) / 100
        }
        return parms;
    }
}

export function getUserAccess(list){
    const projectCreate = list.filter(element => (element.objectId === common.objectId.project && element.operationId === common.operationId.create))
    const projectDelete = list.filter(element => (element.objectId === common.objectId.project && element.operationId === common.operationId.delete))
    const projectModify = list.filter(element => (element.objectId === common.objectId.project && element.operationId === common.operationId.modify))
    const timesheetCreate = list.filter(element => (element.objectId === common.objectId.timesheet && element.operationId === common.operationId.create))
    const timesheetDelete = list.filter(element => (element.objectId === common.objectId.timesheet && element.operationId === common.operationId.delete))
    const timesheetModify = list.filter(element => (element.objectId === common.objectId.timesheet && element.operationId === common.operationId.modify))
    const timesheetApprove = list.filter(element => (element.objectId === common.objectId.timesheet && element.operationId === common.operationId.approve))
    const timesheetDownload = list.filter(element => (element.objectId === common.objectId.timesheet && element.operationId === common.operationId.download)) 
    const userCreate = list.filter(element => (element.objectId === common.objectId.user && element.operationId === common.operationId.create))
    const userDelete = list.filter(element => (element.objectId === common.objectId.user && element.operationId === common.operationId.delete))
    const userModify = list.filter(element => (element.objectId === common.objectId.user && element.operationId === common.operationId.modify))
    const userDisplay = list.filter(element => (element.objectId === common.objectId.user && element.operationId === common.operationId.display))
    const employeeCreate = list.filter(element => (element.objectId === common.objectId.employee && element.operationId === common.operationId.create))
    const employeeDelete = list.filter(element => (element.objectId === common.objectId.employee && element.operationId === common.operationId.delete))
    const employeeModify = list.filter(element => (element.objectId === common.objectId.employee && element.operationId === common.operationId.modify))
    const accountIntergrte = list.filter(element => (element.objectId === common.objectId.intergrted && element.operationId === common.operationId.create))
    const userRoleCreate = list.filter(element => (element.objectId === common.objectId.userRole && element.operationId === common.operationId.create))
    const userRoleDelete = list.filter(element => (element.objectId === common.objectId.userRole && element.operationId === common.operationId.delete))
    const userRoleModify = list.filter(element => (element.objectId === common.objectId.userRole && element.operationId === common.operationId.modify))
    const customerCreate = list.filter(element => (element.objectId === common.objectId.customer && element.operationId === common.operationId.create))
    const customerDelete = list.filter(element => (element.objectId === common.objectId.customer && element.operationId === common.operationId.delete))
    const customerModify = list.filter(element => (element.objectId === common.objectId.customer && element.operationId === common.operationId.modify))
    const invoiceCreate = list.filter(element => (element.objectId === common.objectId.invoice && element.operationId === common.operationId.create))
    const invoiceDelete = list.filter(element => (element.objectId === common.objectId.invoice && element.operationId === common.operationId.delete))
    const invoiceModify = list.filter(element => (element.objectId === common.objectId.invoice && element.operationId === common.operationId.modify))
    const tenantDisplay = list.filter(element => (element.objectId === common.objectId.tenant && element.operationId === common.operationId.display))
    const customerDisplay = list.filter(element => (element.objectId === common.objectId.customer && element.operationId === common.operationId.display))
    const invoiceDisplay = list.filter(element => (element.objectId === common.objectId.invoice && element.operationId === common.operationId.display))
    const appBillingDisplay = list.filter(element => (element.objectId === common.objectId.appBilling && element.operationId === common.operationId.display))
    const employeeDisplay = list.filter(element => (element.objectId === common.objectId.employee && element.operationId === common.operationId.display))
    const timesheetDisplay = list.filter(element => (element.objectId === common.objectId.timesheet && element.operationId === common.operationId.display))
    const userRoleDisplay = list.filter(element => (element.objectId === common.objectId.userRole && element.operationId === common.operationId.display))
    const accountingDisplay = list.filter(element => (element.objectId === common.objectId.accounting && element.operationId === common.operationId.display))
    const appInvoiceDisplay = list.filter(element => (element.objectId === common.objectId.appInvoice && element.operationId === common.operationId.display))
    const paymentMethodDisplay = list.filter(element => (element.objectId === common.objectId.paymentMethod && element.operationId === common.operationId.display))
    const userManagementDisplay = list.filter(element => (element.objectId === common.objectId.userManagement && element.operationId === common.operationId.display))
    const integrationDisplay = list.filter(element => (element.objectId === common.objectId.integration && element.operationId === common.operationId.display))
    const settingDisplay = list.filter(element => (element.objectId === common.objectId.setting && element.operationId === common.operationId.display))
    const projectDisplay = list.filter(element => (element.objectId === common.objectId.project && element.operationId === common.operationId.display))
    const reportDisplay = list.filter(element => (element.objectId === common.objectId.report && element.operationId === common.operationId.display))
    const salesReportDisplay = list.filter(element => (element.objectId === common.objectId.salesReport && element.operationId === common.operationId.display))
    
    const accessList = { 
        createProject: projectCreate && projectCreate.length > 0 && projectCreate[0].accessStatus,
        deleteProject: projectDelete && projectDelete.length > 0 && projectDelete[0].accessStatus,
        modifyProject: projectModify && projectModify.length > 0 && projectModify[0].accessStatus,
        createTimesheet: timesheetCreate && timesheetCreate.length > 0 && timesheetCreate[0].accessStatus,
        deleteTimesheet: timesheetDelete && timesheetDelete.length > 0 && timesheetDelete[0].accessStatus,
        modifyTimesheet: timesheetModify && timesheetModify.length > 0 && timesheetModify[0].accessStatus,
        approveTimesheet: timesheetApprove && timesheetApprove.length > 0 && timesheetApprove[0].accessStatus,
        downloadTimesheet: timesheetDownload && timesheetDownload.length > 0 && timesheetDownload[0].accessStatus,
        createUser: userCreate && userCreate.length > 0 && userCreate[0].accessStatus,
        deleteUser: userDelete && userDelete.length > 0 && userDelete[0].accessStatus,
        modifyUser: userModify && userModify.length > 0 && userModify[0].accessStatus,
        displayUser: userDisplay && userDisplay.length > 0 && userDisplay[0].accessStatus,
        createEmployee: employeeCreate && employeeCreate.length > 0 && employeeCreate[0].accessStatus,
        deleteEmployee: employeeDelete && employeeDelete.length > 0 && employeeDelete[0].accessStatus,
        modifyEmployee: employeeModify && employeeModify.length > 0 && employeeModify[0].accessStatus,
        accountIntergrte: accountIntergrte && accountIntergrte.length > 0 && accountIntergrte[0].accessStatus,
        createUserRole: userRoleCreate && userRoleCreate.length > 0 && userRoleCreate[0].accessStatus,
        deleteUserRole: userRoleDelete && userRoleDelete.length > 0 && userRoleDelete[0].accessStatus,
        modifyUserRole: userRoleModify && userRoleModify.length > 0 && userRoleModify[0].accessStatus,
        createCustomer: customerCreate && customerCreate.length > 0 && customerCreate[0].accessStatus,
        deleteCustomer: customerDelete && customerDelete.length > 0 && customerDelete[0].accessStatus,
        modifyCustomer: customerModify && customerModify.length > 0 && customerModify[0].accessStatus,
        createInvoice: invoiceCreate && invoiceCreate.length > 0 && invoiceCreate[0].accessStatus,
        deleteInvoice: invoiceDelete && invoiceDelete.length > 0 && invoiceDelete[0].accessStatus,
        modifyInvoice: invoiceModify && invoiceModify.length > 0 && invoiceModify[0].accessStatus,
        displayProfile: tenantDisplay && tenantDisplay.length > 0 && tenantDisplay[0].accessStatus,
        displayCustomer: customerDisplay && customerDisplay.length > 0 && customerDisplay[0].accessStatus,
        displayInvoice: invoiceDisplay && invoiceDisplay.length > 0 && invoiceDisplay[0].accessStatus,
        displayAccounting: accountingDisplay && accountingDisplay.length > 0 && accountingDisplay[0].accessStatus,
        displayAppService: appBillingDisplay && appBillingDisplay.length > 0 && appBillingDisplay[0].accessStatus,
        displayEmployee: employeeDisplay && employeeDisplay.length > 0 && employeeDisplay[0].accessStatus,
        displayTimeSheet: timesheetDisplay && timesheetDisplay.length > 0 && timesheetDisplay[0].accessStatus,
        displayUserRoles: userRoleDisplay && userRoleDisplay.length > 0 && userRoleDisplay[0].accessStatus,
        displayAppInvoice: appInvoiceDisplay && appInvoiceDisplay.length > 0 && appInvoiceDisplay[0].accessStatus,
        displayTenantPaymentMethod: paymentMethodDisplay && paymentMethodDisplay.length > 0 && paymentMethodDisplay[0].accessStatus,
        displayUserManagement: userManagementDisplay && userManagementDisplay.length > 0 && userManagementDisplay[0].accessStatus,
        displayIntegration: integrationDisplay && integrationDisplay.length > 0 && integrationDisplay[0].accessStatus,
        displayProject: projectDisplay && projectDisplay.length > 0 && projectDisplay[0].accessStatus,
        displaySetting: settingDisplay && settingDisplay.length > 0 && settingDisplay[0].accessStatus,
        displayReport: reportDisplay && reportDisplay.length > 0 && reportDisplay[0].accessStatus,
        displaySalesReport: salesReportDisplay && salesReportDisplay.length > 0 && salesReportDisplay[0].accessStatus,
        
    }
    return accessList;
}