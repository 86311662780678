import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import BootstrapFormControl from 'react-bootstrap/cjs/FormControl';
import Button from '../../layout/form/Button';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../store/selector/projectSelector';
import * as actions from '../../../store/action/projectAction';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from '../../layout/form/Table';
import Form from 'react-bootstrap/cjs/Form';
import SuccessModal from '../../layout/form/successModal';


export class ProjectMilestone extends Component {

    constructor(props) {
        super(props);

        this.state = {
            milestoneList: [],
            tempActivityList: [],
            initial: true,
            isDeleteActivity: false,
            selectedList: [],
            isAllSelect: false
        };

    };

    static getDerivedStateFromProps(prevProps, prevState) {
        const { selectedProject } = prevProps;
        if (selectedProject && selectedProject.id && prevState.initial) {
            var itemList = []

            if (selectedProject.projectMilestones.length !== 0) {
                for (let i = 0; i < selectedProject.projectMilestones.length; i++) {
                    const element = selectedProject.projectMilestones[i];

                    const newRow = element
                    element.operation = "U"
                    itemList.push(newRow)
                }
            }
            return {
                milestoneList: itemList,
                initial: false
            }
        }

        return null
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            milestoneList,
            isDeleteActivity,
            tempActivityList,
        } = this.state;
        if (isDeleteActivity) {
            const srvActivitiyList = milestoneList.filter(data => (data.operation === "U" || data.operation === "C"))
            const tempItemList = milestoneList.filter(data => (data.id !== null))
            this.setState({
                milestoneList: srvActivitiyList,
                isDeleteActivity: false,
                tempActivityList: tempActivityList.concat(tempItemList)
            })
        }

        if (this.props.projectActivitySuccess !== null) {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }
    }

    createInvoiceTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Create Invoice
        </Tooltip>
    );

    addNewRow = () => {
        var { milestoneList } = this.state;
        var newRow = {
            description: "",
            operation: "C",
            billableFlag: false,
            id: 0
        }
        milestoneList.push(newRow)
        this.setState({ milestoneList })
    }

    handleDescription = (e, rowData) => {
        const { milestoneList } = this.state;
        const newRow = rowData.original
        newRow.description = e.target.value
        milestoneList[rowData.index] = newRow
        this.setState({
            milestoneList,
            isChangeData: true
        })
    }

    handleDescription = (e, rowData) => {
        const { milestoneList } = this.state;
        const newRow = rowData.original
        newRow.description = e.target.value
        milestoneList[rowData.index] = newRow
        this.setState({
            milestoneList,
            isChangeData: true
        })
    }

    handleCheckBillable = (e, rowData) => {
        var { milestoneList } = this.state;
        const newRow = rowData.original
        newRow.billableFlag = e.target.checked
        milestoneList[rowData.index] = newRow
        this.setState({
            milestoneList,
            isChangeData: true
        })
    }

    handleEstimatedEffort = (e, rowData) => {
        const { milestoneList } = this.state;
        const newRow = rowData.original
        newRow.estimatedEffort = e.target.value
        milestoneList[rowData.index] = newRow
        this.setState({
            milestoneList,
            isChangeData: true
        })
    }

    handleEstimatedValue = (e, rowData) => {
        const { milestoneList } = this.state;
        const newRow = rowData.original
        newRow.estimatedValue = e.target.value
        milestoneList[rowData.index] = newRow
        this.setState({
            milestoneList,
            isChangeData: true
        })
    }

    handleApprovedEffort = (e, rowData) => {
        const { milestoneList } = this.state;
        const newRow = rowData.original
        newRow.approvedEffort = e.target.value
        milestoneList[rowData.index] = newRow
        this.setState({
            milestoneList,
            isChangeData: true
        })
    }

    handleApprovedValue = (e, rowData) => {
        const { milestoneList } = this.state;
        const newRow = rowData.original
        newRow.approvedValue = e.target.value
        milestoneList[rowData.index] = newRow
        this.setState({
            milestoneList,
            isChangeData: true
        })
    }

    handleCheckMilestone = (e, data) => {
        const { milestoneList, selectedList } = this.state;
        var list = selectedList
        const checked = e.target.checked;
        const milestone = milestoneList.find(element => element.id === data.id);
        const milestoneIndex = milestoneList.findIndex(element => element.id === data.id);
        const newMilestone = milestone
        milestone.isSelect = checked
        milestoneList[milestoneIndex] = newMilestone
        if (checked) {
            const selectedMilestone = milestone
            list.push(selectedMilestone)
        } else if (!checked) {
            list = selectedList.filter(element => element.id !== data.id);
        }
        this.setState({
            milestoneList,
            selectedList: list
        })
    }

    handleCheckAll = (e) => {
        const { milestoneList } = this.state;
        const checked = e.target.checked
        for (let i = 0; i < milestoneList.length; i++) {
            const milestone = milestoneList[i]
            const element = milestone
            element.isSelect = element.billableFlag ? checked : false
            milestoneList[i] = element
        }
        this.setState({
            isAllSelect: checked,
            milestoneList,
            selectedList: milestoneList.filter(i => i.isSelect === true)
        })
    }

    handleDelete = (rowData) => {
        var { milestoneList } = this.state;
        const newRow = {
            description: rowData.original.description,
            id: rowData.original.id > 0 ? rowData.original.id : null,
            operation: "D"
        }
        milestoneList[rowData.index] = newRow
        this.setState({
            milestoneList,
            isDeleteActivity: true,
            isChangeData: rowData.original.id > 0 ? true : false,
        })
    }

    successModalClose = () => {
        const { selectedProjectId } = this.props;
        this.setState({
            initial: true,
            tempActivityList: []
        })
        this.props.dispatch(actions.loadSelectedProjectRequest(selectedProjectId))
        this.props.dispatch(actions.sendProjectActivitySuccess(null))
    }

    render() {
        const {
            handleClose,
            handleSaveAddNew,
            handleTab,
            modifyProject,
            handleAddInvoice,
            handleCreateInvoice,
            projectActivitySuccess
        } = this.props;

        const { selectedList } = this.state;

        const columns = [{
            Header: () => (
                <div style={{ margin: '5px 0 -10px 0', textAlign: "center" }}>
                    <Form.Group controlId="addressCheckBox">
                        <Form.Check type="checkbox" style={{ fontSize: "14px" }} onChange={(e) => this.handleCheckAll(e)} checked={this.state.isAllSelect} />
                    </Form.Group>
                </div>
            ),
            accessor: 'select',
            width: "3%",
            Cell: ((row) => (
                <div style={{ margin: '5px 0 -10px 0', textAlign: "center" }}>
                    <Form.Group controlId="addressCheckBox">
                        <Form.Check type="checkbox" style={{ fontSize: "14px" }} onChange={(e) => this.handleCheckMilestone(e, row.row.original)} checked={row.row.original.isSelect} disabled={!row.row.original.billableFlag} />
                    </Form.Group>
                </div>
            ))
        }, {
            Header: 'Description',
            accessor: 'description',
            Cell: (rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId={rowData.cell.row.original.description}
                        type="text"
                        defaultValue={rowData.cell.row.original.description}
                        size="sm"
                        onBlur={(e) => this.handleDescription(e, rowData.cell.row)}
                        disabled={!modifyProject}
                    />
                </div>
            ),
        }, {
            Header: 'Billable',
            accessor: 'billableFlag',
            Cell: (rowData) => (
                <div style={{ textAlign: 'center' }}>
                    <Form.Group controlId="billibleCheckBox">
                        <Form.Check type="checkbox" style={{ fontSize: "14px", marginTop: '10px' }} onChange={(e) => this.handleCheckBillable(e, rowData.cell.row)} checked={rowData.cell.row.original.billableFlag}
                            disabled={!modifyProject} />
                    </Form.Group>
                </div>
            ),
            width: "5%",
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Estimated Effort</div>),
            accessor: "estimatedEffort",
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="estimatedEffort"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.estimatedEffort).toFixed(2)}
                        size="sm"
                        onBlur={(e) => this.handleEstimatedEffort(e, rowData.cell.row)}
                        style={{ textAlign: 'right' }}
                        disabled={!modifyProject}
                    />
                </div>
            )),
            width: "10%",
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Estimated Value</div>),
            accessor: 'estimatedValue',
            width: '10%',
            Cell: (rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="estimatedValue"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.estimatedValue).toFixed(2)}
                        size="sm"
                        onBlur={(e) => this.handleEstimatedValue(e, rowData.cell.row)}
                        style={{ textAlign: 'right' }}
                        disabled={!modifyProject}
                    />
                </div>
            ),
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Approved Effort</div>),
            accessor: 'approvedEffort',
            Cell: ((rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="approvedEffort"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.approvedEffort).toFixed(2)}
                        size="sm"
                        onBlur={(e) => this.handleApprovedEffort(e, rowData.cell.row)}
                        style={{ textAlign: 'right' }}
                        disabled={!modifyProject}
                    />
                </div>
            )),
            width: "10%",
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Approved Value</div>),
            accessor: 'approvedValue',
            width: '10%',
            Cell: (rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="approvedValue"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.approvedValue).toFixed(2)}
                        size="sm"
                        onBlur={(e) => this.handleApprovedValue(e, rowData.cell.row)}
                        style={{ textAlign: 'right' }}
                        disabled={!modifyProject}
                    />
                </div>
            ),
        }, {
            Header: 'Status',
            accessor: 'statusDescription',
            Cell: (rowData) => (
                <div>
                    <div style={{ padding: '2px' }}>
                        {rowData.cell.row.original.statusId === "10" ?
                            <div style={{ backgroundColor: '#ff6384', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{rowData.cell.row.original.statusDescription}</div> :
                            // row.row.original.status === "20" ? 
                            // <div style={{backgroundColor:'#36eb69',color:'white',borderRadius:'20px',padding:'2px',paddingLeft:'10px',paddingRight:'10px'}}>{this.setStatusDescription(row.row.original)}</div> :
                            <div style={{ backgroundColor: '#36eb69', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{rowData.cell.row.original.statusDescription}</div>
                        }
                    </div>
                </div>
            ),
            width: '10%',
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Billed Value</div>),
            accessor: 'billedValue',
            width: '8%',
            Cell: (rowData) => (
                <div>
                    <BootstrapFormControl
                        controlId="billedValue"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.billedValue).toFixed(2)}
                        size="sm"
                        onBlur={(e) => this.handleEstimatedValue(e, rowData.cell.row)}
                        style={{ textAlign: 'right' }}
                        disabled
                    />
                </div>
            ),
        }, {
            Header: 'Action',
            width: '8%',
            Cell: ((rowData) => (
                <div>
                    {<span style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <span onClick={modifyProject ? () => this.handleDelete(rowData.cell.row) : ''}><ui5-icon class="samples-margin" name="delete" id={modifyProject ? "table-icon" : "table-icon-disabled"}></ui5-icon></span>
                        <span className="dropdown" style={{ float: 'right' }}>
                            <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>
                            <div className="dropdown-content">
                                <li onClick={rowData.cell.row.original.billableFlag ? () => handleCreateInvoice(rowData.cell.row.original) : ''}><ui5-icon class="samples-margin" name="monitor-payments" id="action-icon"></ui5-icon> Create Invoice</li>
                            </div>
                        </span>
                    </span>}
                </div>
            )),
            accessor: 'action'
        }]

        return (
            <div>
                {(projectActivitySuccess) &&
                    <SuccessModal
                        show={true}
                        massage={projectActivitySuccess}
                        handleClose={this.successModalClose}
                    />}
                <div className='content-body'>
                    <div className="filter-header">
                        <div className="header-pages">
                            <span>Project </span>
                        </div>
                        <div className="btn-close" onClick={handleClose} >
                            <ui5-icon class="samples-margin" name="decline" id="decline"></ui5-icon>
                        </div>
                    </div>
                    <div className="content-header" style={{ margin: '-20px 0 15px 0' }}>
                        <div className="header-pages">
                            <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectDetails', this.state)}><ui5-icon class="samples-margin" name="header" id="title-icon"></ui5-icon> Header</span>
                            <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectMilestone', this.state)}><ui5-icon class="samples-margin" name="add-coursebook" id="title-icon"></ui5-icon> Milestone</span>
                            <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectActivities', this.state)}><ui5-icon class="samples-margin" name="multiselect-all" id="title-icon"></ui5-icon> Activities</span>
                            <span style={{ fontSize: '14px', color: '#4278b7' }} onClick={() => handleTab('projectAssignments', this.state)}><ui5-icon class="samples-margin" name="add-employee" id="title-icon"></ui5-icon> Assignments</span>
                        </div>
                    </div>
                    <div className="profile-content">
                        <div className="page_header">
                            <div className="page_header-title">
                                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
                                    <div><span>Milestone</span></div>
                                    <div className="header-btn">
                                        <div style={{ display: 'flex' }}>
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={this.createInvoiceTooltip}
                                            >
                                                <span className={modifyProject && selectedList.length > 0 ? "header-icon" : "header-icon-disabled"} onClick={modifyProject && selectedList.length > 0 ? () => handleAddInvoice(this.state.selectedList) : ''}>
                                                    <ui5-icon class="samples-margin" name="monitor-payments" id="header-icon"></ui5-icon>
                                                </span>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                                <Table
                                    columns={columns}
                                    data={this.state.milestoneList}
                                />
                                <div className={modifyProject ? "add-icon" : "add-icon-disabled"}>
                                    <ui5-icon class="samples-margin" name="sys-add" id="add-activity" onClick={modifyProject ? () => this.addNewRow() : ''}></ui5-icon>
                                </div>

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="outline-primary"
                                    text='Save'
                                    type="submit"
                                    onClick={modifyProject ? () => handleSaveAddNew(this.state) : ''}
                                    icon='save'
                                    disabled={!modifyProject}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ProjectMilestone.propTypes = {
    isModalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSaveAddNew: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
    selectedProject: selectors.makeSelectSelectedProjectSuccess(),
    projectActivitySuccess: selectors.makeSelectProjectActivitySuccess(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default (compose(withConnect)(ProjectMilestone));