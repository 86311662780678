import {
  LOAD_PROJECT_LIST_ERROR,
  LOAD_PROJECT_LIST_LOADING,
  LOAD_PROJECT_LIST_REQUEST,
  LOAD_PROJECT_LIST_SUCCESS,
  LOAD_SELECTED_PROJECT_SUCCESS,
  SAVE_IS_PROJECT_MODAL_OPEN_STATUS,
  SEND_ADD_NEW_PROJECT_REQUEST,
  SEND_PROJECT_SUCCESS,
  SEND_DELETE_PROJECT_REQUEST,
  SEND_UPDATE_PROJECT_REQUEST,
  LOAD_SELECTED_PROJECT_REQUEST,
  SEND_UPDATE_PROJECT_ACTIVITY_REQUEST,
  SEND_PROJECT_ACTIVITY_SUCCESS,
  SEND_UPDATE_PROJECT_ASSIGNMENT_REQUEST,
  SEND_PROJECT_ASSIGNMENT_SUCCESS,
  LOAD_PROJECT_LIST_BY_STATUS_REQUEST,
  LOAD_PROJECT_LIST_BY_STATUS_SUCCESS,
  LOAD_PROJECT_ALLOCATION_TYPE_REQUEST,
  LOAD_PROJECT_ALLOCATION_TYPE_SUCCESS,
  LOAD_PROJECT_ACTIVITY_LIST_BY_STATUS_REQUEST,
  LOAD_PROJECT_TYPE_REQUEST,
  LOAD_PROJECT_TYPE_SUCCESS,
  LOAD_PROJECT_BILLING_TYPE_REQUEST,
  LOAD_PROJECT_BILLING_TYPE_SUCCESS,
  SEND_IMPORT_PROJECT_ACTIVITY_REQUEST
} from '../constant/projectConstant';

export function saveIsProjectModalOpenStatus(open) {
  return {
    type: SAVE_IS_PROJECT_MODAL_OPEN_STATUS,
    open,
  }
};

export function loadProjectListRequest(data) {
  return {
    type: LOAD_PROJECT_LIST_REQUEST,
    payload: data,
  }
};

export function loadProjectListLoading(data) {
  return {
    type: LOAD_PROJECT_LIST_LOADING,
    payload: data,
  }
};

export function loadProjectListSuccess(data) {
  return {
    type: LOAD_PROJECT_LIST_SUCCESS,
    payload: data,
  }
};

export function loadProjectListError(data) {
  return {
    type: LOAD_PROJECT_LIST_ERROR,
    payload: data,
  }
};

export function sendAddNewProjectRequest(data) {
  return {
    type: SEND_ADD_NEW_PROJECT_REQUEST,
    payload: data,
  }
};

export function sendProjectSuccess(data) {
  return {
    type: SEND_PROJECT_SUCCESS,
    payload: data,
  }
};

export function sendUpdateProjectRequest(data) {
  return {
    type: SEND_UPDATE_PROJECT_REQUEST,
    payload: data,
  }
};

export function sendDeleteProjectRequest(data) {
  return {
    type: SEND_DELETE_PROJECT_REQUEST,
    payload: data,
  }
};

export function loadSelectedProjectSuccess(data) {
  return {
    type: LOAD_SELECTED_PROJECT_SUCCESS,
    payload: data,
  }
};

export function loadSelectedProjectRequest(data) {
  return {
    type: LOAD_SELECTED_PROJECT_REQUEST,
    payload: data,
  }
};

export function sendProjectActivitySuccess(data) {
  return {
    type: SEND_PROJECT_ACTIVITY_SUCCESS,
    payload: data,
  }
};

export function sendUpdateProjectActivityRequest(data) {
  return {
    type: SEND_UPDATE_PROJECT_ACTIVITY_REQUEST,
    payload: data,
  }
};

export function sendProjectAssignmentSuccess(data) {
  return {
    type: SEND_PROJECT_ASSIGNMENT_SUCCESS,
    payload: data,
  }
};

export function sendUpdateProjectAssignmentRequest(data) {
  return {
    type: SEND_UPDATE_PROJECT_ASSIGNMENT_REQUEST,
    payload: data,
  }
};

export function loadProjectActivityListByStatusRequest(data) {
  return {
    type: LOAD_PROJECT_ACTIVITY_LIST_BY_STATUS_REQUEST,
    payload: data,
  }
};

export function loadProjectListByStatusSuccess(data) {
  return {
    type: LOAD_PROJECT_LIST_BY_STATUS_SUCCESS,
    payload: data,
  }
};

export function loadProjectAllocationListRequest(data) {
  return {
    type: LOAD_PROJECT_ALLOCATION_TYPE_REQUEST,
    payload: data,
  }
};

export function loadProjectAllocationListSuccess(data) {
  return {
    type: LOAD_PROJECT_ALLOCATION_TYPE_SUCCESS,
    payload: data,
  }
};

export function loadProjectListByStatusRequest(data) {
  return {
    type: LOAD_PROJECT_LIST_BY_STATUS_REQUEST,
    payload: data,
  }
};

export function loadProjectTypeRequest(data) {
  return {
    type: LOAD_PROJECT_TYPE_REQUEST,
    payload: data,
  }
};

export function loadProjectTypeSuccess(data) {
  return {
    type: LOAD_PROJECT_TYPE_SUCCESS,
    payload: data,
  }
};

export function loadProjectBillingTypeRequest(data) {
  return {
    type: LOAD_PROJECT_BILLING_TYPE_REQUEST,
    payload: data,
  }
};

export function loadProjectBillingTypeSuccess(data) {
  return {
    type: LOAD_PROJECT_BILLING_TYPE_SUCCESS,
    payload: data,
  }
};

export function sendImportProjectActivityRequest(data) {
  return {
    type: SEND_IMPORT_PROJECT_ACTIVITY_REQUEST,
    payload: data,
  }
};