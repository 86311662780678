import React, { Component } from 'react';
import TextField from '../layout/form/TextField';
import Button from '../layout/form/Button';
import { Link } from 'react-router-dom';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
// import { Link } from 'react-router-dom';
import Logo from '../../images/WELAWA.png';
import "./auth.css";
import { changePasswordRequest } from '../service/authService';
import common from '../common';
// import ErrorModal from '../layout/form/errorModal';


class ChangePassword extends Component {
    constructor(props) {
        super(props);

        const user = localStorage.getItem('user')
        this.state ={
            email: user ? user : '',
            confirmationCode:'',
            newPassword:'',
            confirmNewPassword:'',
            isError:false,
            errorMassage:''
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {
            newPassword,
            confirmNewPassword,
            email,
            confirmationCode
        } = this.state

        if(email === '' || newPassword === '' || confirmNewPassword === '' || confirmationCode === ''){
            this.setState({isError:true, errorMassage:common.massages.requiredFieldMassage})
            return
        }
        
        if( newPassword=== confirmNewPassword ){
            const parameters = {
                LoginId: this.state.email,
                ConfirmationCode: this.state.confirmationCode,
                NewPassword: this.state.newPassword
    
            }
            changePasswordRequest(parameters).then(
                (res) =>{ 
                    // console.log(res)
                    this.props.history.push("/")
                },
                (err) =>{
                    // console.log(err.response.data.detail)
                    const massage = err.response.data.detail
                    this.setState({isError:true, errorMassage:massage})
                }
            )
        }else{
            this.setState({isError:true, errorMassage:'Password and confirm password are not matching.'})
        }
        
    }

    getConfirmationCode  = () => {
        this.props.history.push("/forgotPassword")
    }

    // errorModalClose = () => {
    //     this.setState({isError:false, errorMassage:''})
    // }

    componentDidUpdate (){
        if(this.state.isError){
            setTimeout(
                () => this.setState({ isError:false, errorMassage:''}), 
                2000
              );
        }
    }

    render() {
        const {
            isError,
            errorMassage } = this.state
        return (
            <div>
                {/* {isError && 
                    <ErrorModal 
                        show={true} 
                        massage={errorMassage}
                        handleClose={this.errorModalClose}
                    />} */}
                <div className="auth-form">
                    <img alt="vemaslogo" id="vemaslogo" src={Logo}/>
                    <form>
                        <h5>Change Password</h5>
                            <TextField
                                controlId="email"
                                // text="Email"
                                placeholder="Email/Login Id"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.email}
                            />
                            <div>Don't have a Confirmation Code? 
                                <span onClick={this.getConfirmationCode} className="confirm-code"> Get Confirmation Code</span>
                            </div>
                            <TextField
                                controlId="confirmationCode"
                                // text="Confirmation Code "
                                placeholder="Confirmation Code"
                                type="text"
                                onChange={this.handleChange}
                            />
                            <TextField
                                controlId="newPassword"
                                // text="New Password"
                                placeholder="New Password"
                                type="password"
                                onChange={this.handleChange}
                            />
                            <small style={{color:'red', textAlign:"left"}}>
                                <li>Minimum length 8 </li>
                                <li>Require numbers </li>
                                <li>Require special character</li>
                                <li>Require uppercase letters</li>
                                <li>Require lowercase letters</li>
                            </small>
                            <TextField
                                controlId="confirmNewPassword"
                                // text="Confirm New Password"
                                placeholder="Confirm New Password"
                                type="password"
                                onChange={this.handleChange}
                            />
                            {isError ?
                                <div className="authErrorMassage">
                                    <span><ui5-icon class="samples-margin" name="message-error" id="messageError"></ui5-icon></span>
                                    {errorMassage}
                                </div> :
                                <div style={{display:'flex', justifyContent:'center'}}>
                                    <Button 
                                        variant="primary" 
                                        text ='Change Password'
                                        onClick={this.handleSubmit}
                                    />
                            </div>}
                            <div className="auth-pages">
                                <div>Back to <Link to='/'> Sign In</Link>
                            </div>
                        </div>
                    </form>    
                </div>
            </div>
        )
    }
}

export  default ChangePassword;
