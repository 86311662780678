import { createSelector } from 'reselect';

const selectPartDomain = state => state.app;

const makeSelectObjectStatusList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('objectStatusList') : []
  );

const makeSelectUOMList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('uomList') : []
  );

const makeSelectCountryList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('countryList') : []
  );

const makeSelectTenant = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('tenant') : {}
  );


const makeSelectTenantUser = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('tenantUser') : {}
  );

const makeSelectUserAccess = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('userAccess') : {}
  );

const makeSelectCommonSearchData = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('commonSearchData') : {}
  );

const makeSelectAppBillingTermsList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('billingTermsList') : []
  );


const makeSelectAppPackagesList = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('appPackageList') : []
  );

const makeSelectPublicCalendar = () =>
  createSelector(
    selectPartDomain,
    appState =>
      appState ? appState.get('publicCalendar') : []
  );


export {
  makeSelectObjectStatusList,
  makeSelectUOMList,
  makeSelectCountryList,
  makeSelectTenant,
  makeSelectTenantUser,
  makeSelectUserAccess,
  makeSelectCommonSearchData,
  makeSelectAppBillingTermsList,
  makeSelectAppPackagesList,
  makeSelectPublicCalendar
}
