import React,{ Component } from 'react';
import BootstrapModal from 'react-bootstrap/cjs/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import '../../layout/form/form.css';
import BootstrapFormControl from 'react-bootstrap/cjs/FormControl';
import ButtonGroup from '../../layout/form/ButtonGroup';
import SelectField from '../../layout/form/SelectField';
// import TextField from '../../layout/form/TextField';
import TimePicker from '../../layout/form/timePicker';
import Table from '../../layout/form/Table';
// import common from '../../common';
import moment from 'moment';

const customStyles = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 32,
      marginBottom: 0,
      marginTop: -20
    })
};

function setTotal(list) {
    var total = 0
    var breakHours = 0
    for(let i = 0; i < list.length; i++) {
        const element = list[i];
        if(element.typeInclude){
            total = parseFloat(total) + parseFloat(element.hours)
        }else{
            breakHours = parseFloat(breakHours) + parseFloat(element.hours)
        }
    }
    const value = {
        total: total,
        breakHours: breakHours
    }
    return value;
}

function calHours(start, end) {
    var time_start = new Date();
    var time_end = new Date();
    var value_start = [];
    var value_end = [];
    var hourDiff = 0
    value_start = start.split(':');
    value_end = end.split(':');
    time_start.setHours(value_start[0], value_start[1], value_start[2], 0)
    time_end.setHours(value_end[0], value_end[1], value_end[2], 0)
    hourDiff = time_end - time_start // millisecond 
    var tempTime = moment.duration(hourDiff);
    var hours = tempTime.hours() + tempTime.minutes()/60;
    if(hours < 0){
        hours = 0
    }
    return parseFloat(hours)
}

function CreateRow  (list){
    var itemList = []
    for (let i = 0; i < list.length; i++) {
        const element = list[i];
        const newRow = {
            type: element.type,
            startTime: element.startTime,
            endTime: element.endTime,
            hours: calHours(element.startTime,element.endTime),
            operation: element.operation && element.operation === "D" ? "D" : "U",
            id: element.id,
            note: element.note,
            typeInclude: element.typeInclude,
            projectActivityId: element.projectActivityId,
            projectId: element.projectId
        }
        itemList.push(newRow)
    }
    return itemList
}


export class TimesheetTimeDetails extends Component {
    constructor(props) {
        super(props);
    
        this.state = { 
            timeDetailList:[],
            tempTimeDetailList:[],
            initial:true,
            isDeleteTimeDetail: false,
            totalHours: 0,
            breakHours: 0,
            timeDetailsStartTime: "06:00:00",
            timeDetailsEndTime: "06:00:00"
        }

    }

    static getDerivedStateFromProps(prevProps,prevState) {
        const { initial,timeDetailsStartTime,timeDetailsEndTime } = prevState;
        const { timesheetDetails } = prevProps;

        if(timesheetDetails && timesheetDetails.length && initial){
            var itemList = []
            var value = {}
            itemList = CreateRow(timesheetDetails)
            value = setTotal(itemList)
            var endTime = timeDetailsEndTime
            var startTime = timeDetailsStartTime
            if(itemList.length > 0){
                const lastRow = itemList[itemList.length - 1]
                startTime = lastRow.endTime
                endTime= lastRow.endTime
            }
            return {
                initial: false,
                timeDetailList: itemList,
                totalHours: value.total,
                breakHours: value.breakHours,
                timeDetailsStartTime: startTime,
                timeDetailsEndTime: endTime
            }
        }
        return null;
    }

    addNewRow = () => {
        var { timeDetailList,timeDetailsEndTime, timeDetailsStartTime } = this.state;
        const { summarisedProjectList } = this.props;
        const project = summarisedProjectList.result.find(e => e.id === this.props.selectedCellData.original.projectActivityId)
        var newRow = {
            type: 0,
            startTime: timeDetailsStartTime,
            endTime: timeDetailsEndTime,
            hours: 0,
            operation: "C",
            id: null,
            note: '',
            typeInclude: false,
            projectActivityId: this.props.selectedCellData.original.projectActivityId,
            projectId: project.projectId,
            workingDate: this.props.selectdDate
        }
        timeDetailList.push(newRow)
        this.setState({
            timeDetailList
        })
    }

    handleType = (id,rowData) =>{
        const { timeDetailList } = this.state;
        const { timeTypeList } = this.props;
        const element = timeTypeList.find(e => e.id === id)
        const newRow = rowData.original
        newRow.type = id
        newRow.typeInclude = element.include
        newRow.operation = "U"

        timeDetailList[rowData.index] = newRow
        const value = setTotal(timeDetailList)
        this.setState({
            timeDetailList,
            totalHours: value.total,
            breakHours: value.breakHours
        })
    }

    handleStartTimeChange = (seconds,rowData) => {
        const { timeDetailList } = this.state;
        var date = new Date(null);
        date.setSeconds(seconds);
        const hhmmssFormat = date.toISOString().substr(11, 8);
        var lastRow = timeDetailList[timeDetailList.length - 2]
        if(timeDetailList.length >= 2 && hhmmssFormat < lastRow.endTime){
            return
        }
        const hours = calHours(hhmmssFormat,rowData.original.endTime)
        const newRow = rowData.original
        newRow.startTime = hhmmssFormat
        newRow.hours = hours

        if(newRow.endTime < newRow.startTime){
            newRow.endTime = hhmmssFormat
        }
        timeDetailList[rowData.index] = newRow
        const value = setTotal(timeDetailList)
        this.setState({
            timeDetailList,
            totalHours: value.total,
            breakHours: value.breakHours
        })
    }

    handleEndTimeChange = (seconds,rowData) => {
        const { timeDetailList } = this.state;
        var date = new Date(null);
        date.setSeconds(seconds);
        const hhmmssFormat = date.toISOString().substr(11, 8);
        const row = timeDetailList[rowData.index]
        if(hhmmssFormat < row.startTime){
            return
        }
        const hours = calHours(rowData.original.startTime,hhmmssFormat)
        const newRow = rowData.original
        newRow.endTime = hhmmssFormat
        newRow.hours = hours

        timeDetailList[rowData.index] = newRow
        const value = setTotal(timeDetailList)
        this.setState({
            timeDetailList,
            totalHours: value.total,
            breakHours: value.breakHours,
            timeDetailsStartTime: hhmmssFormat,
            timeDetailsEndTime: hhmmssFormat
        })
    }

    handleNote = (e,rowData) => {
        const { timeDetailList } = this.state;
        const newRow =  rowData.original
        newRow.note =  e.target.value
        timeDetailList[rowData.index] = newRow
        this.setState({
            timeDetailList
        })
    }

    handleDelete = (rowData) => {
        const { timeDetailList } = this.state;
        const newRow =  rowData.original
        newRow.operation = "D" 
        timeDetailList[rowData.index] = newRow
        this.setState({
            timeDetailList, 
            isDeleteTimeDetail:true
        })
    }

    componentDidUpdate(prevProps, prevState){
        const { 
            timeDetailList, 
            isDeleteTimeDetail, 
            tempTimeDetailList,
        } = this.state;
        if(isDeleteTimeDetail){
            const timeList = timeDetailList.filter(data => (data.operation === "U" || data.operation === "C"))
            const tempItemList = timeDetailList.filter(data => (data.id !== null && data.operation === "D"))
            const value = setTotal(timeList)
            this.setState({
                timeDetailList:timeList, 
                isDeleteTimeDetail:false, 
                tempTimeDetailList:tempTimeDetailList.concat(tempItemList),
                totalHours: value.total,
                breakHours: value.breakHours
            })
        }
    }

    render() {
        const {
            show,
            handleClose,
            handleSaveDetails,
            title,
            modifyTimesheet,
            timeTypeList,
            timesheetDetails
        } = this.props;

        const { 
            timeDetailList,
            totalHours,
            breakHours,
            tempTimeDetailList
        } = this.state;

        const columns = [{
            Header: 'Type',
            accessor: 'type',
            width:'18%',
            Cell:(rowData) =>(
                <div>
                    <SelectField
                        controlId="type"
                        onChange={(e)=>this.handleType(e,rowData.cell.row)}
                        options={timeTypeList && timeTypeList.length > 0 ? timeTypeList : []}
                        style={customStyles}
                        selectedValue={rowData.cell.row.original.type}
                        // display='firstName'
                        disabled={!modifyTimesheet}
                    />
                </div>
              ),    
          },{
            Header: 'Start time',
            accessor: 'startTime',
            width:'10%',
            Cell:(rowData) =>(
                <div style={{margin:'10px 0 -10px 0'}}>
                    <TimePicker
                        controlId={rowData.cell.row.original.startTime}
                        onChange={(e) => this.handleStartTimeChange(e,rowData.cell.row)}
                        value={rowData.cell.row.original.startTime}
                        disabled={!modifyTimesheet || rowData.cell.row.original.type === 0}
                    />
                </div>
              ),    
          },{
            Header: 'End time',
            accessor: 'endTime',
            width:'10%',
            Cell:(rowData) =>(
                <div style={{margin:'10px 0 -10px 0'}}>
                    <TimePicker
                        controlId={rowData.cell.row.original.endTime}
                        onChange={(e) => this.handleEndTimeChange(e,rowData.cell.row)}
                        value={rowData.cell.row.original.endTime}
                        disabled={!modifyTimesheet || rowData.cell.row.original.type === 0}
                    />
                </div>
              ),    
          },{
            Header: () => (<div style={{textAlign:"right"}}>Hours</div>),
            accessor: 'hours',
            width:'10%',
            Cell:((rowData)=>(
                <div>
                    <BootstrapFormControl 
                        controlId="total"
                        type="number"
                        defaultValue={parseFloat(rowData.cell.row.original.hours).toFixed(2)}
                        size="sm"
                        style={{textAlign:'right'}}
                        disabled
                    />
                </div>
            )),   
          },{
            Header: 'Note',
            accessor: 'note',
            Cell:(rowData) =>(
                <div>
                    <BootstrapFormControl 
                        controlId={rowData.cell.row.original.note}
                        type="text"
                        defaultValue={rowData.cell.row.original.note}
                        size="sm"
                        onBlur={(e)=>this.handleNote(e,rowData.cell.row)}
                        disabled={!modifyTimesheet || rowData.cell.row.original.type === 0}
                    />
                </div>
              ),    
          },{
            Header:'Action',
            width:'8%',
            Cell:((rowData)=>(
                <div>
                    {<span style={{display:'flex',justifyContent:'center'}}>
                        <span onClick={modifyTimesheet ? ()=>this.handleDelete(rowData.cell.row) : ''}><ui5-icon class="samples-margin" name="delete"  id={modifyTimesheet ? "table-icon": "table-icon-disabled"}></ui5-icon></span>
                    </span>}
                </div>
            )),
            accessor:'action'
          }]

        return (
            <div>
                <BootstrapModal 
                    show={show} 
                    onHide={handleClose}
                    animation={false}
                    // dialogClassName="modal_width"
                    centered
                    size='xl'
                    >
                    <div>
                        <BootstrapModal.Header closeButton>
                            <BootstrapModal.Title style={{fontSize:'1.1rem'}}>Time entry for {title}</BootstrapModal.Title>
                        </BootstrapModal.Header>
                        <BootstrapModal.Body style={{maxHeight: 'calc(100vh - 200px)',minHeight:'calc(100vh - 300px)', overflowY: 'auto'}}>
                            <form style={{marginTop:"5px"}}>
                                <div style={{marginBottom:'10px'}}>
                                    <Table
                                        columns={columns}
                                        data={timeDetailList.filter(data => (data.operation !== "D"))}
                                    />
                                    <div className="add-icon">
                                        <ui5-icon class="samples-margin" name="sys-add" id={modifyTimesheet ? "table-icon": "table-icon-disabled"} onClick={modifyTimesheet ?() => this.addNewRow() : ''}></ui5-icon>
                                    </div>
                                    
                                </div>
                                <div style={{display:'flex', justifyContent:'flex-end', marginRight:'10px', fontSize:'16px', fontWeight:700}}>
                                    <div style={{marginRight:'30px'}}>
                                        Total hours: { (totalHours || breakHours) ? parseFloat(totalHours + breakHours).toFixed(2) : "0.00" }
                                    </div>
                                    <div style={{marginRight:'30px'}}>
                                        Worked hours: {totalHours ? parseFloat(totalHours).toFixed(2) : "0.00"}
                                    </div>
                                    <div>
                                        Break time: {breakHours ? parseFloat(breakHours).toFixed(2) : "0.00"}
                                    </div>
                                </div>
                            </form>
                        </BootstrapModal.Body>
                        <BootstrapModal.Footer>
                            <ButtonGroup
                                primaryBtnClick={handleClose}
                                primaryBtnVariant="outline-primary"
                                primaryBtnText='Close'
                                secondaryBtnClick={() => handleSaveDetails(totalHours, timeDetailList, tempTimeDetailList)}
                                secondaryBtnText={timesheetDetails.length > 0 ? 'Update':'Add'}                               
                                secondaryBtnVariant="outline-primary"
                                secondaryBtnDisabled = {!modifyTimesheet || (totalHours === 0 && timesheetDetails.length === 0)} 
                                
                            />
                        </BootstrapModal.Footer>
                    </div>
                </BootstrapModal>
            </div>)
        }
}

TimesheetTimeDetails.propTypes = {
    show: PropTypes.any,
    onHide: PropTypes.any,
    handleClose:PropTypes.any,
    title: PropTypes.any,
}

export default TimesheetTimeDetails;