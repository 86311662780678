import { fromJS } from 'immutable';
import {
  LOAD_PROJECT_ALLOCATION_TYPE_SUCCESS,
  LOAD_PROJECT_BILLING_TYPE_SUCCESS,
  LOAD_PROJECT_LIST_BY_STATUS_SUCCESS,
  LOAD_PROJECT_LIST_ERROR,
  LOAD_PROJECT_LIST_LOADING,
  LOAD_PROJECT_LIST_SUCCESS,
  LOAD_PROJECT_TYPE_SUCCESS,
  LOAD_SELECTED_PROJECT_SUCCESS,
  SAVE_IS_PROJECT_MODAL_OPEN_STATUS,
  SEND_PROJECT_ACTIVITY_SUCCESS,
  SEND_PROJECT_ASSIGNMENT_SUCCESS,
  SEND_PROJECT_SUCCESS,
} from '../constant/projectConstant';

export const initialState = fromJS({
  isProjectModalOpen: false,
  projectList: [],
  isProjectListLoading: false,
  projectListError: null,
  projectSuccess: null,
  selectedProject: '',
  projectActivitySuccess: null,
  projectAssignmentSuccess: null,
  summarisedProjectList: [],
  projectAllocationTypeList: [],
  projectTypeList: [],
  projectBillingTypeList: []
});

function projectReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_IS_PROJECT_MODAL_OPEN_STATUS:
      return state.set('isProjectModalOpen', action.open)
    case LOAD_PROJECT_LIST_SUCCESS:
      return state.set('projectList', action.payload)
    case LOAD_PROJECT_LIST_LOADING:
      return state.set('isProjectListLoading', action.payload)
    case LOAD_PROJECT_LIST_ERROR:
      return state.set('projectListError', action.payload)
    case SEND_PROJECT_SUCCESS:
      return state.set('projectSuccess', action.payload)
    case LOAD_SELECTED_PROJECT_SUCCESS:
      return state.set('selectedProject', action.payload)
    case SEND_PROJECT_ACTIVITY_SUCCESS:
      return state.set('projectActivitySuccess', action.payload)
    case SEND_PROJECT_ASSIGNMENT_SUCCESS:
      return state.set('projectAssignmentSuccess', action.payload)
    case LOAD_PROJECT_LIST_BY_STATUS_SUCCESS:
      return state.set('summarisedProjectList', action.payload)
    case LOAD_PROJECT_ALLOCATION_TYPE_SUCCESS:
      return state.set('projectAllocationTypeList', action.payload)
    case LOAD_PROJECT_TYPE_SUCCESS:
      return state.set('projectTypeList', action.payload)
    case LOAD_PROJECT_BILLING_TYPE_SUCCESS:
      return state.set('projectBillingTypeList', action.payload)
    default:
      return state;
  }
}

export default projectReducer;
