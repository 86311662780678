import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../store/selector/timesheetSelector';
import * as actions from '../../../store/action/timesheetAction';
import { makeSelectTenantOpenHoursSuccess } from '../../../store/selector/tenantSelector';
import PropTypes from 'prop-types';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Table from '../../layout/form/Table';
import SelectField from '../../layout/form/SelectField';
import ButtonGroup from '../../layout/form/ButtonGroup';
import Modal from '../../layout/form/Modal';
import Loader from '../../layout/form/Loader';
import ErrorModal from '../../layout/form/errorModal';
import SuccessModal from '../../layout/form/successModal';
import DeleteModal from '../../layout/form/deleteModal';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DatePicker from '../../layout/form/datePicker';
import TextField from '../../layout/form/TextField';
import Pagination from '../../layout/form/Pagination';
import common, { invalidToken, refeshTokenset } from '../../common';
import AddTimesheet from './AddTimesheet';
import { loadProjectActivityListByStatusRequest, loadProjectAllocationListRequest } from '../../../store/action/projectAction';
import { loadObjectStatusRequest } from '../../../store/action/action';
import { makeSelectObjectStatusList } from '../../../store/selector/selector';
import { makeSelectUserAccessSuccess } from '../../../store/selector/usersSelector';
import ExportModal from './ExportModal';
import { exportTimesheet } from '../../service/timesheetService';
import { saveLoginUser } from '../../../store/action/authAction';
import { CSVLink } from 'react-csv';
import Confirm from './Confirm';

var prevDate = new Date();
var nextDate = new Date()
const today = new Date()
const first = today.getDate() - today.getDay() + 3
const monday = new Date(today.setDate(first))
const weekStartDate = moment(monday).format(common.dateFormat)
prevDate.setDate(1);
prevDate.setMonth(prevDate.getMonth() - 2);
nextDate.setDate(nextDate.getDate() + 2)
const dateFormat = sessionStorage.getItem("dateFormat")

class Timesheet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFilterFormOpen: false,
            isUpdate: true,
            fromDate: moment(prevDate).format(common.dateFormat),
            toDate: moment(nextDate).format(common.dateFormat),
            status: '',
            pageNumber: 1,
            pageSize: 20,
            isValidationError: false,
            isDeleteModalOpen: false,
            selectedRow: {},
            isRejectModalOpen: false,
            rejectTimesheet: '',
            notes: '',
            isExportModalOpen: false,
            weekStartDate: weekStartDate,
            weekStartDateTo: weekStartDate,
            weekStartDateFrom: weekStartDate,
            csvHeaders: [],
            csvData: [],
            isConfirmModalOpen: false,
            selectedTimesheet: '',
            initial: true,
            isGetTimesheetList: true
        }
        this.csvLinkEl = React.createRef();
    }

    static getDerivedStateFromProps(prevProps, prevState) {
        const { tenantOpenHours } = prevProps;
        const { initial, fromDate, toDate } = prevState
        if (initial && tenantOpenHours && tenantOpenHours.id > 0) {
            const weekDay = today.getDate() - today.getDay() + tenantOpenHours.weekStartDay - 1
            const firstDate = new Date(today.setDate(weekDay))
            const weekStartDate_ = moment(firstDate).format(common.dateFormat)

            const fromDate_ = new Date(fromDate)
            const toDate_ = new Date(toDate)
            const startDate = fromDate_.getDate() - fromDate_.getDay() + tenantOpenHours.weekStartDay - 1
            const endDate = toDate_.getDate() - toDate_.getDay() + tenantOpenHours.weekStartDay + 5
            const startWeekFirstDate = new Date(fromDate_.setDate(startDate))
            const endtWeekFirstDate = new Date(toDate_.setDate(endDate))
            return {
                initial: false,
                weekStartDate: weekStartDate_,
                weekStartDateTo: weekStartDate_,
                weekStartDateFrom: weekStartDate_,
                fromDate: moment(startWeekFirstDate).format(common.dateFormat),
                toDate: moment(endtWeekFirstDate).format(common.dateFormat),
            }
        }

        return null;
    }

    componentDidMount() {
        this.props.dispatch(actions.loadSelectedTimesheetSuccess(''))
        this.props.dispatch(actions.saveIsTimesheetOpenStatus(false))
        this.props.dispatch(loadProjectAllocationListRequest("50"))
        this.props.dispatch(loadProjectActivityListByStatusRequest("50"))
        this.props.dispatch(loadObjectStatusRequest(common.objectId.timesheet));
        this.props.dispatch(actions.loadTimeTypeRequest())
        this.props.dispatch(actions.loadTimesheetListLoading(true))
    }

    getTimesheetList = () => {
        const parameters = {
            pageSize: this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber: this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            status: this.state.status,
            toDate: this.state.toDate,
            fromDate: this.state.fromDate
        }
        this.props.dispatch(actions.loadTimesheetListRequest(parameters))
    }

    addTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Add new timesheet
        </Tooltip>
    );

    filterTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Filter
        </Tooltip>
    );

    csvTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Export
        </Tooltip>
    );

    handleAdd = (isUpdate, data) => {
        const parms = {
            weekStartDate: data.weekStartDate,
            userId: data.userId
        }
        this.props.dispatch(actions.loadSelectedTimesheetSuccess(''))
        this.props.dispatch(actions.loadSelectedTimesheetRequest(parms))
        this.props.dispatch(actions.saveIsTimesheetOpenStatus(true))
        this.setState({
            isUpdate: isUpdate
        })
    }

    handleAddNew = (isUpdate) => {
        const userId = sessionStorage.getItem("tenantUserId")
        const parms = {
            weekStartDate: this.state.weekStartDate,
            userId: userId
        }
        this.props.dispatch(actions.loadSelectedTimesheetSuccess(''))
        this.props.dispatch(actions.loadSelectedTimesheetRequest(parms))
        this.props.dispatch(actions.saveIsTimesheetOpenStatus(true))
        this.setState({
            isUpdate: isUpdate
        })
    }

    handleClose = () => {
        this.props.dispatch(actions.loadSelectedTimesheetSuccess(''))
        this.props.dispatch(actions.saveIsTimesheetOpenStatus(false))
        this.handleRejectModalClose()
        this.handleConfirmClose()
        this.getTimesheetList()
    }

    handleDeleteConfirm = (data) => {
        this.setState({ isDeleteModalOpen: true, selectedRow: data })
    }

    handleDeleteModalClose = () => {
        this.setState({ isDeleteModalOpen: false, selectedRow: {} })
    }

    handleDeleteTimesheet = () => {
        this.handleDeleteModalClose()
        this.props.dispatch(actions.sendDeleteTimesheetRequest(this.state.selectedRow.id))
    }

    handleSave = () => {
        const { selectedTimesheet } = this.state;

        const times = []
        for (let j = 0; j < selectedTimesheet.times.length; j++) {
            var details = selectedTimesheet.times[j]
            if (details.day1Details.length > 0) {
                for (let i = 0; i < details.day1Details.length; i++) {
                    const element = details.day1Details[i]
                    times.push(element)
                }
            }
        }

        const parameters = {
            weekStartDate: selectedTimesheet.weekStartDate,
            id: selectedTimesheet.id,
            timeDetails: times,
            statusId: selectedTimesheet.statusId,
            totalHours: selectedTimesheet.totalHours
        }
        if (selectedTimesheet.id > 0) {
            this.props.dispatch(actions.sendUpdateTimesheetRequest(parameters))
        } else
            this.props.dispatch(actions.sendAddNewTimesheetRequest(parameters))

    }

    handleSaveAddNew = (details, status) => {
        // const tempItemList = details.tempTimesheetsList.filter(data => (data.operation === "D"))
        // const newList = details.timesheets
        // const lastRow = newList[newList.length - 1]
        // if(lastRow && lastRow.lastRow === true){
        //     newList.pop();
        // }
        // const times = newList.concat(tempItemList)
        // if(times.length === 0){
        //     this.setState({isValidationError:true, errorMassage:'please add time'})
        //     return
        // }
        // for(let i = 0; i < times.length; i++) {
        //     const element = times[i]
        //     if(element.projectId <= 0){
        //         this.setState({isValidationError:true, errorMassage:'please select project'})
        //         return
        //     }
        // }
        if(details.timesheets && details.timesheets.length > 1){
            const item = details.timesheets[0]
            if(item && item.projectActivityId === 0){
                this.setState({ isValidationError: true, errorMassage: 'Please select a project and add your times' })
                return
            }
        }
        const parameters = {
            weekStartDate: details.weekStartDate,
            id: details.id ? details.id : 0,
            statusId: status,
            totalHours: details.totalHours,
            userName: details.userName ? details.userName : null
        }
        this.setState({
            isConfirmModalOpen: true,
            selectedTimesheet: parameters
        })
    }

    handleSubmit = () => {
        const { selectedTimesheet } = this.state;
        const parameters = {
            id: selectedTimesheet.id,
            statusId: selectedTimesheet.statusId,
            totalHours: selectedTimesheet.totalHours
        }
        this.props.dispatch(actions.sendUpdateTimesheetStatusRequest(parameters))
    }

    handleSaveStatus = (details, status) => {
        const tempItemList = details.tempTimesheetsList.filter(data => (data.operation === "D"))
        var newList = details.timesheets
        const lastRow = newList[newList.length - 1]
        if (lastRow && lastRow.lastRow === true) {
            newList.pop();
        }
        const times = newList.concat(tempItemList)
        const parameters = {
            weekStartDate: details.weekStartDate,
            id: details.id,
            times: times,
            statusId: status,
            totalHours: details.totalHours,
            approverNote: null,
            userName: details.userName ? details.userName : null
        }
        this.setState({
            isConfirmModalOpen: true,
            selectedTimesheet: parameters
        })
    }

    handleRejectTimesheet = (details, status) => {
        const tempItemList = details.tempTimesheetsList.filter(data => (data.operation === "D"))
        var newList = details.timesheets
        const lastRow = newList[newList.length - 1]
        if (lastRow && lastRow.lastRow === true) {
            newList.pop();
        }
        const times = newList.concat(tempItemList)
        const parameters = {
            weekStartDate: details.weekStartDate,
            id: details.id,
            times: times,
            statusId: status,
            totalHours: details.totalHours
        }
        this.setState({
            isRejectModalOpen: true,
            rejectTimesheet: parameters
        })
        // this.props.dispatch(actions.sendUpdateTimesheetStatusRequest(parameters))
    }

    handleReject = () => {
        const { rejectTimesheet, notes } = this.state;
        const parameters = {
            weekStartDate: rejectTimesheet.weekStartDate,
            id: rejectTimesheet.id,
            times: rejectTimesheet.times,
            statusId: rejectTimesheet.statusId,
            totalHours: rejectTimesheet.totalHours,
            approverNote: notes,
        }
        this.props.dispatch(actions.sendUpdateTimesheetStatusRequest(parameters))
    }

    handleRejectModalClose = () => {
        this.setState({
            isRejectModalOpen: false,
            rejectTimesheet: '',
            notes: ''
        })
    }

    getNextPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber: this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber: page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize: size,
            pageNumber: 1
        })
    }

    setDateFormat = (value) => {
        const date = moment(value).format(dateFormat ? dateFormat : common.dateFormat);
        return date
    }

    setEndDateFormat = (value) => {
        const day = new Date(value)
        const weekLastDay = day.setDate(day.getDate() + 6)
        const date = moment(weekLastDay).format(dateFormat ? dateFormat : common.dateFormat);
        return date
    }

    setStatusDescription = (data) => {
        const { objectStatusList } = this.props;
        if (objectStatusList && objectStatusList.length && objectStatusList.length !== 0) {
            const desc = objectStatusList.find(select => select.id === data.statusId)
            return (desc && desc.description ? desc.description : '')
        }
    }

    handleChangeSelect = (value, id) => {
        this.setState({
            [id]: value
        })
    }

    handleDateChange = (date, id) => {
        const { tenantOpenHours } = this.props;
        if (date === null) {
            return
        }
        if (id === 'weekStartDateFrom' || id === 'weekStartDateTo') {
            const currentDate = new Date(date)
            const first = currentDate.getDate() - currentDate.getDay() + tenantOpenHours.weekStartDay - 1
            const weekMonday = new Date(currentDate.setDate(first))
            const startDate = moment(weekMonday).format(common.dateFormat)
            this.setState({
                [id]: startDate,
            })
        }
        else {
            this.setState({
                [id]: moment(date).format(common.dateFormat)
            })
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    errorModalClose = () => {
        this.setState({ isValidationError: false, errorMassage: '' })
        this.props.dispatch(actions.loadTimesheetListError(null))
    }

    successModalClose = () => {
        this.props.dispatch(actions.sendTimesheetSuccess(null))
        this.handleClose()
    }

    handleFilterForm = () => {
        this.setState({
            isFilterFormOpen: !this.state.isFilterFormOpen,
            status: this.state.status,
            toDate: this.state.toDate,
            fromDate: this.state.fromDate
        })
    }

    handleFilterFormClose = () => {
        const { tenantOpenHours } = this.props;
        const fromDate_ = new Date(prevDate)
        const toDate_ = new Date(nextDate)
        const startDate = fromDate_.getDate() - fromDate_.getDay() + tenantOpenHours.weekStartDay - 1
        const endDate = toDate_.getDate() - toDate_.getDay() + tenantOpenHours.weekStartDay + 5
        const startWeekFirstDate = new Date(fromDate_.setDate(startDate))
        const endtWeekFirstDate = new Date(toDate_.setDate(endDate))
        this.setState({
            isFilterFormOpen: false,
            status: '',
            pageNumber: 1,
            pageSize: 20,
            fromDate: moment(startWeekFirstDate).format(common.dateFormat),
            toDate: moment(endtWeekFirstDate).format(common.dateFormat),
        })

        const parameters = {
            status: '',
            pageNumber: 1,
            pageSize: 20,
            fromDate: moment(startWeekFirstDate).format(common.dateFormat),
            toDate: moment(endtWeekFirstDate).format(common.dateFormat),
        }
        this.props.dispatch(actions.loadTimesheetListRequest(parameters))
    }


    handleExportModal = () => {
        this.setState({
            isExportModalOpen: true
        })
    }

    handleExportModalClose = () => {
        this.setState({
            isExportModalOpen: false,
            csvHeaders: [],
            csvData: []
        })
    }

    handleConfirmClose = () => {
        this.setState({
            isConfirmModalOpen: false,
            selectedTimesheet: ''
        })
    }

    handleGetCSV = async () => {
        if (this.state.weekStartDateFrom === null ||
            this.state.weekStartDateTo === null) {
            this.setState({ isValidationError: true, errorMassage: 'Select Report Period' })
            return
        }
        this.props.dispatch(actions.loadTimesheetListLoading(true));
        const response = await this.getReportCSV();

        if (response && response.data && response.data.statusCode && response.data.statusCode === 200 && response.data.result.errorCode && response.data.result.errorCode === 5000) {
            const massage = 'Data not available for requested period'
            this.props.dispatch(actions.loadTimesheetListError(massage))
        }
        else if (response && response.data && response.data.statusCode && response.data.statusCode === 200) {
            // console.log(response.data.result)
            const data = response.data.result.tableData
            //   data.push({Label:'{}'})
            this.setState({
                csvData: data,
                csvHeaders: response.data.result.header
            }, () => {
                setTimeout(() => {
                    this.csvLinkEl.current.link.click();
                    this.handleExportModalClose()
                });
            });
        }
        else if (response && response.data && response.data.statusCode && response.data.statusCode === 403) {
            this.props.dispatch(saveLoginUser(false))
            invalidToken()
        }
        else {
            const massage = common.error
            this.props.dispatch(actions.loadTimesheetListError(massage))
        }
        if (response && response.data && response.data.result && response.data.result.newAccessToken) {
            refeshTokenset(response.data.result.newAccessToken)
        }
        this.props.dispatch(actions.loadTimesheetListLoading(false));
    }

    getReportCSV = () => {
        const parameters = {
            startDate: moment(this.state.weekStartDateFrom).format(common.dateFormat),
            endDate: moment(this.state.weekStartDateTo).format(common.dateFormat)
        }
        return exportTimesheet(parameters).then(
            (response) => response,
            (err) => err
        )
    }

    handleFilter = () => {
        this.getTimesheetList()
        this.handleFilterForm()
    }

    componentDidUpdate(prevProps, prevState) {
        const { tenantOpenHours } = this.props;
        if(this.state.isGetTimesheetList && tenantOpenHours && tenantOpenHours.id > 0){
            this.getTimesheetList()
            this.setState({
                isGetTimesheetList: false
            })
        }
        if (this.props.timesheetSuccess !== null) {
            setTimeout(
                () => this.successModalClose(),
                1000
            );
        }
        if ((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)) {
            this.getTimesheetList()
        }
    }

    render() {

        const {
            isTimesheetModalOpen,
            timesheetList,
            isTimesheetListLoading,
            timesheetListError,
            timesheetSuccess,
            objectStatusList,
            userAccess,
            tenantOpenHours
        } = this.props;

        const {
            isFilterFormOpen,
            isDeleteModalOpen,
            selectedRow,
            isRejectModalOpen,
            isExportModalOpen,
            weekStartDateTo,
            weekStartDateFrom,
            csvHeaders,
            csvData,
            isConfirmModalOpen,
            selectedTimesheet
        } = this.state;

        const columns = [{
            Header: 'Start Date',
            accessor: 'weekStartDate',
            Cell: ((row) => (
                <div >
                    {row.row.original.weekStartDate === '1/1/0001' ? '' : this.setDateFormat(row.row.original.weekStartDate)}
                </div>
            )),
            width: "15%",
        }, {
            Header: 'End Date',
            accessor: 'endDate',
            Cell: ((row) => (
                <div >
                    {row.row.original.weekStartDate === '1/1/0001' ? '' : this.setEndDateFormat(row.row.original.weekStartDate)}
                </div>
            )),
            width: "15%",
        }, {
            Header: () => (<div style={{ textAlign: "right" }}>Hours</div>),
            accessor: 'totalHours',
            width: "10%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {row.row.original.totalHours ? parseFloat(row.row.original.totalHours).toFixed(2) : "0.00"}
                </div>
            ))
        }, {
            Header: 'Notes',
            accessor: 'approverNote',
        }, {
            Header: 'Status',
            accessor: 'statusId',
            width: "8%",
            Cell: ((row) => (
                <div style={{ padding: '2px' }}>
                    {row.row.original.statusId === "10" ?
                        <div style={{ backgroundColor: '#ebd936', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                        row.row.original.statusId === "60" ?
                            <div style={{ backgroundColor: '#36eb69', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                            row.row.original.statusId === "30" ?
                                <div style={{ backgroundColor: '#6da4e4', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                                row.row.original.statusId === "40" ?
                                    <div style={{ backgroundColor: '#eb7f36', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                                    row.row.original.statusId === "20" ?
                                        <div style={{ backgroundColor: '#a2eb36', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                                        <div style={{ backgroundColor: '#ff6384', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div>
                    }
                </div>
            )),
        }, {
            Header: 'Action',
            width: "8%",
            Cell: ((row) => (
                <div>
                    {/* <Button */}
                    {<span style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <span onClick={() => this.handleAdd(true, row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>
                        <span onClick={(userAccess && userAccess.deleteTimesheet && row.row.original.statusId === "10") ? () => this.handleDeleteConfirm(row.row.original) : ''}><ui5-icon class="samples-margin" name="delete" id={userAccess && userAccess.deleteTimesheet && row.row.original.statusId === "10" ? "table-icon" : "table-icon-disabled"}></ui5-icon></span>
                        {/* <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>  */}
                    </span>}
                    {/* /> */}
                </div>
            )),
            accessor: 'action'
        }]

        const tenantColumns = [
            {
                Header: 'Employee',
                accessor: 'userName',
                width: "18%",
            }, {
                Header: 'Start Date',
                accessor: 'weekStartDate',
                Cell: ((row) => (
                    <div >
                        {row.row.original.weekStartDate === '1/1/0001' ? '' : this.setDateFormat(row.row.original.weekStartDate)}
                    </div>
                )),
                width: "12%",
            }, {
                Header: 'End Date',
                accessor: 'endDate',
                Cell: ((row) => (
                    <div >
                        {row.row.original.weekStartDate === '1/1/0001' ? '' : this.setEndDateFormat(row.row.original.weekStartDate)}
                    </div>
                )),
                width: "12%",
            }, {
                Header: () => (<div style={{ textAlign: "right" }}>Hours</div>),
                accessor: 'totalHours',
                width: "10%",
                Cell: ((row) => (
                    <div style={{
                        textAlign: "right"
                    }}>
                        {row.row.original.totalHours ? parseFloat(row.row.original.totalHours).toFixed(2) : "0.00"}
                    </div>
                ))
            }, {
                Header: 'Notes',
                accessor: 'approverNote',
            }, {
                Header: 'Status',
                accessor: 'statusId',
                width: "8%",
                Cell: ((row) => (
                    <div style={{ padding: '2px' }}>
                        {row.row.original.statusId === "10" ?
                            <div style={{ backgroundColor: '#ebd936', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                            row.row.original.statusId === "60" ?
                                <div style={{ backgroundColor: '#36eb69', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                                row.row.original.statusId === "30" ?
                                    <div style={{ backgroundColor: '#6da4e4', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                                    row.row.original.statusId === "40" ?
                                        <div style={{ backgroundColor: '#eb7f36', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                                        row.row.original.statusId === "20" ?
                                            <div style={{ backgroundColor: '#a2eb36', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div> :
                                            <div style={{ backgroundColor: '#ff6384', color: 'white', borderRadius: '20px', padding: '2px', paddingLeft: '10px', paddingRight: '10px' }}>{this.setStatusDescription(row.row.original)}</div>
                        }
                    </div>
                )),
            }, {
                Header: 'Action',
                width: "8%",
                Cell: ((row) => (
                    <div>
                        {/* <Button */}
                        {<span style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <span onClick={() => this.handleAdd(true, row.row.original)}><ui5-icon class="samples-margin" name="edit" id="table-icon"></ui5-icon></span>
                            <span onClick={(userAccess && userAccess.deleteTimesheet && row.row.original.statusId === "10") ? () => this.handleDeleteConfirm(row.row.original) : ''}><ui5-icon class="samples-margin" name="delete" id={userAccess && userAccess.deleteTimesheet && row.row.original.statusId === "10" ? "table-icon" : "table-icon-disabled"}></ui5-icon></span>
                            {/* <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>  */}
                        </span>}
                        {/* /> */}
                    </div>
                )),
                accessor: 'action'
            }
        ]

        return (
            <div>
                {isTimesheetListLoading ?
                    <Loader show={isTimesheetListLoading} /> : ''}

                {(this.state.isValidationError || timesheetListError !== null) &&
                    <ErrorModal
                        show={true}
                        massage={this.state.errorMassage ? this.state.errorMassage : timesheetListError}
                        handleClose={this.errorModalClose}
                    />}
                {timesheetSuccess !== null &&
                    <SuccessModal
                        show={true}
                        massage={timesheetSuccess}
                        handleClose={this.successModalClose}
                    />}
                {isDeleteModalOpen &&
                    <DeleteModal
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteTimesheet}
                        title={selectedRow.id}
                    />}
                {isRejectModalOpen &&
                    <Modal
                        show={isRejectModalOpen}
                        onHide={this.handleRejectModalClose}
                        title="Reason for Rejection"
                        width='40%'
                        body={
                            <div>
                                {/* <h5>Basic Data</h5> */}
                                <form style={{ marginTop: "5px" }}>
                                    <div className="col-sm">
                                        <TextField
                                            text="Notes"
                                            controlId="notes"
                                            defaultValue={this.state.notes}
                                            onBlur={this.handleChange}
                                            as="textarea"
                                            // row={50}
                                            style={{ height: 200 }}
                                        />
                                    </div>
                                </form>
                            </div>
                        }
                        footer={
                            <div>
                                <ButtonGroup
                                    primaryBtnClick={this.handleRejectModalClose}
                                    primaryBtnVariant="outline-primary"
                                    // primaryBtnText='Cancel'
                                    // primaryBtnIcon='decline'
                                    primaryBtnText='Go Back'
                                    primaryBtnIcon='undo'
                                    secondaryBtnClick={() => this.handleReject(this.state)}
                                    secondaryBtnText='Save'
                                    secondaryBtnVariant="outline-primary"
                                    secondaryBtnIcon='save'
                                />
                            </div>
                        }
                    />
                }
                {isConfirmModalOpen &&
                    <Confirm
                        show={isConfirmModalOpen}
                        handleModalClose={this.handleConfirmClose}
                        title={
                            <div>
                                {selectedTimesheet.statusId === "10" ? "If you want to save as a draft ?" :
                                    selectedTimesheet.statusId === "30" ? "If you want to submit ?" :
                                        selectedTimesheet.statusId === "20" ? "Are you sure you approve of this ?" : ''} </div>
                        }
                        content={
                            <div>
                                {selectedTimesheet.userName ? <div>Employee: {selectedTimesheet.userName}</div> : ''}
                                <div>Weekstart: {selectedTimesheet.weekStartDate}</div>
                                <div>Total worked hours: {selectedTimesheet.totalHours}</div>
                            </div>
                        }
                        handleConfirm={() => this.handleSubmit()
                            // selectedTimesheet.statusId === "10" ? () => this.handleSave() : 
                            // selectedTimesheet.statusId === "30" ? () => this.handleSave() :
                            // selectedTimesheet.statusId === "20" ? () => this.handleApprove() :   ''
                        }
                    />
                }
                {isExportModalOpen ?
                    <ExportModal
                        isExportModalOpen={isExportModalOpen}
                        handleModalClose={this.handleExportModalClose}
                        weekStartDateTo={weekStartDateTo}
                        weekStartDateFrom={weekStartDateFrom}
                        handleDateChange={this.handleDateChange}
                        handleGetCSV={this.handleGetCSV}
                    />
                    : ''
                }
                <CSVLink
                    headers={csvHeaders}
                    filename={weekStartDateFrom}
                    data={csvData}
                    ref={this.csvLinkEl}
                    enclosingCharacter={``}
                />
                {isTimesheetModalOpen ?
                    <div>
                        <AddTimesheet
                            handleClose={this.handleClose}
                            handleSaveAddNew={this.handleSaveAddNew}
                            handleSaveStatus={this.handleSaveStatus}
                            handleRejectTimesheet={this.handleRejectTimesheet}
                            isUpdate={this.state.isUpdate}
                            modifyTimesheet={userAccess.modifyTimesheet}
                            createTimesheet={userAccess.createTimesheet}
                            approveTimesheet={userAccess.approveTimesheet}
                            weekStartDate={this.state.weekStartDate}
                            tenantOpenHours={tenantOpenHours}
                        />
                    </div> :
                    <div>
                        <div className="content-header">
                            <div className="header-pages">
                                <ui5-icon class="samples-margin" name="fob-watch" id="header-icon"></ui5-icon><span>TIMESHEET</span>
                            </div>
                        </div>
                        <div className="content-body">
                            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0' }}>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <h5 style={{ marginLeft: '5px' }}>Timesheet List</h5>
                                    </div>
                                </div>
                                <div className="header-btn">
                                    <div style={{ display: 'flex' }}>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={this.addTooltip}
                                        >
                                            <span className={userAccess && userAccess.createTimesheet ? "header-icon" : "header-icon-disabled"} onClick={userAccess && userAccess.createTimesheet ? () => this.handleAddNew(false) : ''}>
                                                <ui5-icon class="samples-margin" name="add" id="header-icon"></ui5-icon>
                                            </span>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={this.filterTooltip}
                                        >
                                            <span className="header-icon" onClick={() => this.handleFilterForm()}>
                                                <ui5-icon class="samples-margin" name="filter" id="header-icon"></ui5-icon>
                                            </span>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={this.csvTooltip}
                                        >
                                            <span className={userAccess && userAccess.downloadTimesheet ? "header-icon" : "header-icon-disabled"} onClick={userAccess && userAccess.downloadTimesheet ? () => this.handleExportModal() : ''}>
                                                <ui5-icon class="samples-margin" name="download-from-cloud" id="header-icon"></ui5-icon>
                                            </span>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            {isFilterFormOpen &&
                                <div className="filter-form">
                                    <div className="filter-header">
                                        <h5>Filter Form</h5>
                                        <div className="btn-close" onClick={this.handleFilterFormClose} >
                                            <ui5-icon class="samples-margin" name="decline" id="btn-icon"></ui5-icon>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm">
                                            <SelectField
                                                text="Status"
                                                controlId="status"
                                                options={objectStatusList}
                                                onChange={(e) => this.handleChangeSelect(e, "status")}
                                                selectedValue={this.state.status}
                                                isClearable
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <DatePicker
                                                text="From date"
                                                controlId="fromDate"
                                                onChange={(e) => this.handleDateChange(e, "fromDate")}
                                                value={this.state.fromDate === null ? '' : new Date(this.state.fromDate)}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <DatePicker
                                                text="To date"
                                                controlId="toDate"
                                                onChange={(e) => this.handleDateChange(e, "toDate")}
                                                value={this.state.fromDate === null ? '' : new Date(this.state.toDate)}
                                            />
                                        </div>
                                    </div>
                                    <div className="filter-btns">
                                        <ButtonGroup
                                            primaryBtnClick={this.handleFilter}
                                            primaryBtnIcon='search'
                                            primaryBtnText='Search'
                                            primaryBtnVariant="outline-primary"
                                            secondaryBtnClick={this.handleFilterFormClose}
                                            secondaryBtnIcon='clear-filter'
                                            secondaryBtnText='Clear'
                                            secondaryBtnVariant="outline-primary"
                                        />
                                    </div>
                                </div>}
                            <div className="table-content">
                                <Table
                                    columns={userAccess && userAccess.approveTimesheet ? tenantColumns : columns}
                                    data={timesheetList.result && timesheetList.result.length ? timesheetList.result : []}
                                />
                            </div>
                            <Pagination
                                currentPage={timesheetList.page ? timesheetList.page : this.state.pageNumber}
                                getNextPage={this.getNextPage}
                                getPrevPage={this.getPrevPage}
                                totalPage={timesheetList.totalPages}
                                setClickedPage={this.setClickedPage}
                                currentPageSize={this.state.pageSize}
                                setPageSize={this.setPageSize}
                            />
                        </div>
                    </div>}
            </div>
        )
    }
}

Timesheet.propTypes = {
    isTimesheetModalOpen: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
    isTimesheetModalOpen: selectors.makeSelectTimesheetOpen(),
    timesheetList: selectors.makeSelectTimesheetList(),
    isTimesheetListLoading: selectors.makeSelectTimesheetListLoading(),
    timesheetListError: selectors.makeSelectTimesheetListError(),
    timesheetSuccess: selectors.makeSelectTimesheetSuccess(),
    objectStatusList: makeSelectObjectStatusList(),
    userAccess: makeSelectUserAccessSuccess(),
    tenantOpenHours: makeSelectTenantOpenHoursSuccess(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default (compose(withConnect)(Timesheet));