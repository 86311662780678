import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from '../../../../store/selector/tenantSelector';
import * as actions from '../../../../store/action/tenantAction';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import Image from 'react-bootstrap/Image';
import File from 'react-bootstrap/cjs/FormFile';
import Label from 'react-bootstrap/cjs/FormLabel';
import Button from '../../../layout/form/Button';
import TextField from '../../../layout/form/TextField';
import User from '../../../../images/user.png';
import SelectField from '../../../layout/form/SelectField';
import common from '../../../common';
import Avatar from 'react-avatar';
import { makeSelectCountryList } from '../../../../store/selector/selector';
import Loader from '../../../layout/form/Loader';
import ErrorModal from '../../../layout/form/errorModal';
import SuccessModal from '../../../layout/form/successModal';
import { Link } from 'react-router-dom';
// import Form from 'react-bootstrap/Form';

const convertBase64 = (file) =>{
    return new Promise((resolve,reject)=>{
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () =>{
            resolve(fileReader.result)
        }

        fileReader.onerror = (error) =>{
            reject(error)
        }
    })
}

class BasicData extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            initial:true,
            businessName:'',
            streetNo:'',
            streetName:'',
            cityName:'',
            stateName:'',
            postalCode:'',
            country:'',
            registrationNo:'',
            email:'',
            phoneNumber:'',
            faxNumber:'',
            seletedImage:'',
            errorMassage:'',
            isValidationError:false,
            isChangesBasicData: false
        }
    }

    static getDerivedStateFromProps(prevProps,prevState) {
        const { initial } = prevState;
        const { tenantDetails} = prevProps; 
        if(tenantDetails && tenantDetails.id && initial ){
            return{
                businessName:tenantDetails.businessName,
                streetNo:tenantDetails.billStreetNumber ? tenantDetails.billStreetNumber : '',
                streetName:tenantDetails.billStreetName ? tenantDetails.billStreetName : '',
                cityName:tenantDetails.billCity ? tenantDetails.billCity :'',
                stateName:tenantDetails.billRegion ? tenantDetails.billRegion : '',
                postalCode:tenantDetails.billPostalCode ? tenantDetails.billPostalCode : '',
                country:tenantDetails.billCountry && tenantDetails.billCountry.code ? tenantDetails.billCountry.code : '',
                licenseNumber:tenantDetails.licenseNumber ? tenantDetails.licenseNumber : '',
                registrationNo:tenantDetails.businessRegistrationNumber ? tenantDetails.businessRegistrationNumber : '' ,
                email:tenantDetails.email,
                phoneNumber:tenantDetails.phoneNumber,
                faxNumber:tenantDetails.fax,
                seletedImage:tenantDetails.logo,
                initial:false
            }
        }
        return null
    }

    componentDidMount (){
        this.getTenantDetails()
    }

    getTenantDetails =()=>{
        const id = sessionStorage.getItem("tenantId")
        this.props.dispatch(actions.loadTenantBasicSuccess({}))
        this.props.dispatch(actions.loadTenantBasicRequest(id))
        this.setState({
            isChangesBasicData: false
        })
    }
        
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
            isChangesBasicData: true
        })
    }

    handleSelect = (value, id) => {
        this.setState({
            [id]: value,
            isChangesBasicData: true
        })
    }

    handleChangeLogo = async (e) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        this.setState({
            seletedImage: base64,
            isChangesBasicData: true
        })
    }

    errorModalClose = () => {
        this.setState({isValidationError:false, errorMassage:''})
        this.props.dispatch(actions.loadTenantDetailsError(''))
    }

    successModalClose = () => {
        this.getTenantDetails()
        this.props.dispatch(actions.sendTenantDetailsSuccess(''))
        this.setState({
            initial:true
        })
    }
    
    setCountryDescription = (data) => {
        const { countryList } = this.props;
        if(countryList && countryList.length && countryList.length !== 0){
          const desc = countryList.find(select =>  select.id === data)
          return ((desc && desc.description) ? desc.description : '')
        }
    }

    componentDidUpdate (){
        if (this.props.tenantDetailsSuccess !== ''){
            setTimeout(
                () => this.successModalClose(), 
                1000
            );
        }
    }

    render() {
        const { 
            countryList,
            isTenantDetailsLoading,
            tenantDetailsError,
            tenantDetailsSuccess,
            handleTab,
            handleSubmit
        } = this.props;
        return (
            <div>
                {(isTenantDetailsLoading ) ? 
                    <Loader show={isTenantDetailsLoading}/> :''}

                {(this.state.isValidationError || tenantDetailsError !== '' ) && 
                        <ErrorModal
                            show={true} 
                            massage={this.state.errorMassage ? this.state.errorMassage : tenantDetailsError}
                            handleClose={this.errorModalClose}
                        />}

                {(tenantDetailsSuccess) && 
                        <SuccessModal
                            show={true} 
                            massage={tenantDetailsSuccess}
                            handleClose={this.successModalClose}
                        />}
                <div className="content-header">
                    <div className="header-pages">
                        <span onClick={() => handleTab('basicData',this.state)}><Link><ui5-icon class="samples-margin" name="home" style={{color:'#000000', width:'1.5rem', padding:'2px'}}></ui5-icon> Basic Data</Link></span>
                        <span onClick={() => handleTab('finance',this.state)}><ui5-icon class="samples-margin" name="customer-financial-fact-sheet" style={{color:'#000000', width:'1.5rem', padding:'2px'}}></ui5-icon> Finance</span>
                        {/* <span onClick={() => handleTab('correspondence',this.state)}><ui5-icon class="samples-margin" name="letter" style={{color:'#000000', width:'1.5rem', padding:'2px'}}></ui5-icon> Correspondence</span> */}
                        <span onClick={() => handleTab('openingHours',this.state)}><ui5-icon class="samples-margin" name="date-time" style={{color:'#000000', width:'1.5rem', padding:'2px'}}></ui5-icon> Calendar</span>
                        {/* <span onClick={() => handleTab('marketing',this.state)}><ui5-icon class="samples-margin" name="official-service" style={{color:'#000000', width:'1.5rem', padding:'2px'}}></ui5-icon> Marketing</span> */}
                    </div>
                </div>
                <div className="profile-content">
                    <div className="page_header">
                        <div className="page_header-title">
                            <span>Basic Data</span>
                        </div>
                        <div className='row'>
                            <div className='col-sm-4' style={{border:'1px solid #becddf', borderRadius:'5px', margin:'10px 5px 5px 5px', paddingTop:'50px'}}>
                                <div style={{textAlign:'center'}}>
                                    <div>
                                        <Avatar name={this.state.businessName} size={100} round="50%" maxInitials={2}/>
                                    </div>
                                    <div className='profile-businessName' style={{margin:'20px 0', borderBottom:'1px solid #becddf'}}>
                                        {this.state.businessName}
                                    </div>
                                    {this.state.registrationNo !== '' ? <div>
                                        REG : {this.state.registrationNo}
                                    </div> :''}
                                    {this.state.licenseNumber !== '' ? <div>
                                        LIC : {this.state.licenseNumber}
                                    </div> :''}
                                    <div style={{margin:'10px 0'}}>
                                        {this.state.streetName !== '' ? <div>
                                            {this.state.streetNo}, {this.state.streetName}, {this.state.cityName}, {this.state.postalCode}
                                        </div> :''}
                                        {this.state.country !== '' ? <div>
                                            {this.state.stateName}, {this.setCountryDescription(this.state.country)}
                                        </div> :''}
                                    </div>
                                    <div>
                                        Phone : {this.state.phoneNumber}
                                    </div>
                                    <div>
                                        Email : {this.state.email}
                                    </div>
                                    <div style={{margin:'30px 0'}}>
                                        <Image className="imageView" src={this.state && this.state.seletedImage ? this.state.seletedImage : User} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm'>
                                <div className='profile-details'>
                                    <TextField
                                        text="Business name"
                                        controlId="businessName"
                                        placeholder="Business Name"
                                        type="text"
                                        onChange={this.handleChange}
                                        required={true}
                                        defaultValue={this.state.businessName}
                                        maxLength={common.maxLengths.businessName}
                                    />
                                    <div className="row">
                                        <div className="col-sm">
                                            <TextField
                                                text="Business registration no"
                                                controlId="registrationNo"
                                                placeholder="Registration No"
                                                type="text"
                                                onChange={this.handleChange}
                                                required={true}
                                                defaultValue={this.state.registrationNo}
                                                maxLength={common.maxLengths.brn}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <TextField
                                                text="License number"
                                                controlId="licenseNumber"
                                                placeholder="License number"
                                                type="text"
                                                onChange={this.handleChange}
                                                defaultValue={this.state.licenseNumber}
                                                maxLength={common.maxLengths.licenseNo}
                                            />
                                        </div>
                                    </div>
                                    {/* <div style={{display:'flex', justifyContent:'flex-end'}}>
                                        <Form.Check 
                                            type="switch"
                                            id="isOnline"
                                            label="Show findamechanic.com.au"
                                            defaultChecked={this.state.isOnline}
                                            onChange={handleChangeSwitch}
                                        />
                                    </div> */}
                                </div>
                                <div className='profile-details'>
                                    <div className="row">
                                        <div className="col-sm">
                                            <TextField
                                                text="Street No"
                                                controlId="streetNo"
                                                placeholder="Street No"
                                                type="text"
                                                onChange={this.handleChange}
                                                defaultValue={this.state.streetNo}
                                                maxLength={common.maxLengths.tenantStreetNo}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <TextField
                                                text="Street Name"
                                                controlId="streetName"
                                                placeholder="Street Name"
                                                type="text"
                                                onChange={this.handleChange}
                                                required={true}
                                                defaultValue={this.state.streetName}
                                                maxLength={common.maxLengths.streetName}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm">
                                            <TextField
                                                text="City"
                                                controlId="cityName"
                                                placeholder="City"
                                                type="text"
                                                onChange={this.handleChange}
                                                required={true}
                                                defaultValue={this.state.cityName}
                                                maxLength={common.maxLengths.cityName}
                                            /> 
                                        </div>
                                        <div className="col-sm">
                                            <TextField
                                                text="State"
                                                controlId="stateName"
                                                placeholder="State"
                                                type="text"
                                                onChange={this.handleChange}
                                                required={true}
                                                defaultValue={this.state.stateName}
                                                maxLength={common.maxLengths.streetName}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm">
                                            <TextField
                                                text="Postal Code"
                                                controlId="postalCode"
                                                placeholder="Postal Code"
                                                type="text"
                                                required={true}
                                                onChange={this.handleChange}
                                                defaultValue={this.state.postalCode}
                                                maxLength={common.maxLengths.postCode}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            {/* <TextField
                                                text="Country"
                                                controlId="country"
                                                as="select"
                                                options={countryList}
                                                onChange={this.handleChange}
                                                defaultValue={this.state.country}
                                            /> */}
                                            <SelectField
                                                text="Country"
                                                controlId="country"
                                                onChange={(e) =>this.handleSelect(e,'country')}
                                                options={countryList}
                                                required={true}
                                                selectedValue={this.state.country}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='profile-details'>
                                    <div className="row">
                                        <div className="col-sm">
                                            <TextField
                                                text="Phone Number"
                                                controlId="phoneNumber"
                                                placeholder="Phone Number"
                                                type="text"
                                                onChange={this.handleChange}
                                                defaultValue={this.state.phoneNumber}
                                                maxLength={common.maxLengths.phone}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <TextField
                                                text="Fax Number"
                                                controlId="faxNumber"
                                                placeholder="Fax Number"
                                                type="text"
                                                onChange={this.handleChange}
                                                defaultValue={this.state.faxNumber}
                                                maxLength={common.maxLengths.fax}
                                            />
                                        </div>
                                    </div>
                                    <TextField
                                        text="Email"
                                        controlId="email"
                                        placeholder="Email"
                                        type="email"
                                        onChange={this.handleChange}
                                        required={true}
                                        defaultValue={this.state.email}
                                        maxLength={common.maxLengths.email}
                                    />
                                </div>
                                <div className='profile-details'>
                                    <Label style={{fontWeight:500}}>Business Logo</Label>
                                    <div className="userPhoto">
                                        <Image className="imageView" src={this.state && this.state.seletedImage ? this.state.seletedImage : User} />
                                        <File id="userPhotoUpload" hidden ref={photoUpload => this.photoUpload = photoUpload} onChange={this.handleChangeLogo}/>
                                        <div style={{display:'flex', justifyContent:'center'}}>
                                            <Button 
                                                variant="outline-primary" 
                                                text ='Change'
                                                size='sm'
                                                onClick={() => this.photoUpload.click()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btnSave">
                            <Button
                                variant="outline-primary" 
                                text ='Save'
                                onClick={() => handleSubmit(this.state)}
                                icon="save"
                            />
                        </div>
                    </div>
                    {/* <div className="col-sm page_header">
                        <div className="page_header-title">
                            <span>Service coverage</span>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}

BasicData.propTypes = {
    tenantDetails: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
    tenantDetails: selectors.makeSelectTenantBasic(),
    countryList: makeSelectCountryList(),
    isTenantDetailsLoading: selectors.makeSelectTenantLoading(),
    tenantDetailsError: selectors.makeSelectTenantError(),
    tenantDetailsSuccess: selectors.makeSelectTenantUpdateSuccess(),
  });
  
  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
  );
  

export default (compose(withConnect)(BasicData));
