const PATH = 'app/Project';

export const SAVE_IS_PROJECT_MODAL_OPEN_STATUS = `${PATH}SAVE_IS_PROJECT_MODAL_OPEN_STATUS`;
export const LOAD_PROJECT_LIST_REQUEST = `${PATH}LOAD_PROJECT_LIST_REQUEST`;
export const LOAD_PROJECT_LIST_ERROR = `${PATH}LOAD_PROJECT_LIST_ERROR`;
export const LOAD_PROJECT_LIST_LOADING = `${PATH}LOAD_PROJECT_LIST_LOADING`;
export const LOAD_PROJECT_LIST_SUCCESS = `${PATH}LOAD_PROJECT_LIST_SUCCESS`;
export const SEND_ADD_NEW_PROJECT_REQUEST = `${PATH}SEND_ADD_NEW_PROJECT_REQUEST`;
export const SEND_PROJECT_SUCCESS = `${PATH}SEND_PROJECT_SUCCESS`;
export const SEND_UPDATE_PROJECT_ACTIVITY_REQUEST = `${PATH}SEND_UPDATE_PROJECT_ACTIVITY_REQUEST`;
export const SEND_PROJECT_ACTIVITY_SUCCESS = `${PATH}SEND_PROJECT_ACTIVITY_SUCCESS`;
export const SEND_UPDATE_PROJECT_ASSIGNMENT_REQUEST = `${PATH}SEND_UPDATE_PROJECT_ASSIGNMENT_REQUEST`;
export const SEND_PROJECT_ASSIGNMENT_SUCCESS = `${PATH}SEND_PROJECT_ASSIGNMENT_SUCCESS`;
export const SEND_UPDATE_PROJECT_REQUEST = `${PATH}SEND_UPDATE_PROJECT_REQUEST`;
export const SEND_DELETE_PROJECT_REQUEST = `${PATH}SEND_DELETE_PROJECT_REQUEST`;
export const LOAD_SELECTED_PROJECT_REQUEST = `${PATH}LOAD_SELECTED_PROJECT_REQUEST`;
export const LOAD_SELECTED_PROJECT_SUCCESS = `${PATH}LOAD_SELECTED_PROJECT_SUCCESS`;
export const LOAD_PROJECT_ACTIVITY_LIST_BY_STATUS_REQUEST = `${PATH}LOAD_PROJECT_ACTIVITY_LIST_BY_STATUS_REQUEST`;
export const LOAD_PROJECT_LIST_BY_STATUS_SUCCESS = `${PATH}LOAD_PROJECT_LIST_BY_STATUS_SUCCESS`;
export const LOAD_PROJECT_ALLOCATION_TYPE_REQUEST = `${PATH}LOAD_PROJECT_ALLOCATION_TYPE_REQUEST`;
export const LOAD_PROJECT_ALLOCATION_TYPE_SUCCESS = `${PATH}LOAD_PROJECT_ALLOCATION_TYPE_SUCCESS`;
export const LOAD_PROJECT_LIST_BY_STATUS_REQUEST = `${PATH}LOAD_PROJECT_LIST_BY_STATUS_REQUEST`;
export const LOAD_PROJECT_TYPE_REQUEST = `${PATH}LOAD_PROJECT_TYPE_REQUEST`;
export const LOAD_PROJECT_TYPE_SUCCESS = `${PATH}LOAD_PROJECT_TYPE_SUCCESS`;
export const LOAD_PROJECT_BILLING_TYPE_REQUEST = `${PATH}LOAD_PROJECT_BILLING_TYPE_REQUEST`;
export const LOAD_PROJECT_BILLING_TYPE_SUCCESS = `${PATH}LOAD_PROJECT_BILLING_TYPE_SUCCESS`;
export const SEND_IMPORT_PROJECT_ACTIVITY_REQUEST = `${PATH}SEND_IMPORT_PROJECT_ACTIVITY_REQUEST`;