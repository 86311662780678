import { createSelector } from 'reselect';

const selectTimesheetDomain = state => state.timesheet;

const makeSelectTimesheetOpen =() =>
  createSelector(
    selectTimesheetDomain,
    titleState => 
      titleState ? titleState.get('isTimesheetModalOpen') : false
  );

const makeSelectTimesheetList = () =>
  createSelector(
    selectTimesheetDomain,
    titleState => 
      titleState ? titleState.get('timesheetList') : []
  );

const makeSelectTimesheetListLoading = () =>
  createSelector(
    selectTimesheetDomain,
    titleState => 
      titleState ? titleState.get('isTimesheetListLoading') : false
  );

const makeSelectTimesheetListError = () =>
  createSelector(
    selectTimesheetDomain,
    titleState => 
      titleState ? titleState.get('timesheetListError') : null
  );

const makeSelectTimesheetSuccess = () =>
  createSelector(
    selectTimesheetDomain,
    titleState => 
      titleState ? titleState.get('timesheetSuccess') : null
  );

const makeSelectSelectedTimesheetSuccess = () =>
  createSelector(
    selectTimesheetDomain,
    titleState => 
      titleState ? titleState.get('selectedTimesheet') : ''
  );

const makeSelectTimeTypeSuccess = () =>
  createSelector(
    selectTimesheetDomain,
    titleState => 
      titleState ? titleState.get('timeTypeList') : []
  );

const makeSelectTimesheetDetailsSuccess = () =>
  createSelector(
    selectTimesheetDomain,
    titleState => 
      titleState ? titleState.get('timesheetDetails') : []
  );

const makeSelectUpdateTimesheetDetailsSuccess = () =>
  createSelector(
    selectTimesheetDomain,
    titleState => 
      titleState ? titleState.get('timesheetDetailsSuccess') : false
  );

export {
    makeSelectTimesheetOpen,
    makeSelectTimesheetList,
    makeSelectTimesheetListLoading,
    makeSelectTimesheetListError,
    makeSelectTimesheetSuccess,
    makeSelectSelectedTimesheetSuccess,
    makeSelectTimeTypeSuccess,
    makeSelectTimesheetDetailsSuccess,
    makeSelectUpdateTimesheetDetailsSuccess
}
