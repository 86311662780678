import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './NavBar.css';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";

function CustomMenu({
    settingMenuClose,
    userDisplayAccess,
    userAccess
}) {

    return (
        <div className='setting_menu'>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={settingMenuClose} >
                <ui5-icon class="samples-margin" name="decline" id="decline" ></ui5-icon>
            </div>

            {userAccess && userAccess.displayUserManagement &&
                (userAccess.displayUserRoles || userDisplayAccess) &&
                <h5>User Management</h5>}
            <div className="row">
                <div className="col-sm">
                    {userAccess && userAccess.displayUserRoles && <li className='setting_text'>
                        <Link to='./userRoles'>User Roles</Link>
                    </li>}
                </div>
                <div className="col-sm">
                    {userDisplayAccess ? <li className='setting_text'>
                        <Link to='./users'>Users</Link>
                    </li> : ''}
                </div>
                <div className="col-sm"></div>
            </div>

            {userAccess && userAccess.displaySetting &&
                (userAccess.displayProfile) && <h5>Settings</h5>}
            <div className='row'>
                <div className="col-sm-4">
                    {userAccess && userAccess.displayProfile && <li className='setting_text'>
                        <Link to='./profile'>Company Profile</Link>
                    </li>}
                </div>
                <div className="col-sm-4">
                    {userAccess && userAccess.displayAppService && <li className='setting_text'>
                        <Link to='./appBilling'>Billing Management</Link>
                    </li>}
                </div>
            </div>

            {userAccess && userAccess.displayIntegration &&
                (userAccess.displayAccounting) && <h5>Integration</h5>}
            <div className="row">
                <div className="col-sm-4">
                    {userAccess && userAccess.displayAccounting && <li className='setting_text'>
                        <Link to='./accounting'>Accounting</Link>
                    </li>}
                </div>
            </div>
        </div>
    )
}

CustomMenu.propTypes = {
    isSideBarStatus: PropTypes.bool,
    redirectProduct: PropTypes.func,
    redirectMake: PropTypes.func,
    redirectModel: PropTypes.func,
    redirectRepair: PropTypes.func,
}

export default CustomMenu;