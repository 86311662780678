import { combineReducers } from 'redux'
import projectReducer from './projectReducer'
import navBarReducer from './navBarReducer'
// import titleReducer from './titleReducer'
import authReducer from './authReducer'
import appReducer from './appReducer'
import tenantReducer from './tenantReducer'
import vehicleReducer from './vehicleReducer'
import usersReducer from './usersReducer'
import timesheetReducer from './timesheetReducer'
import employeeReducer from './employeeReducer'
import accountReducer from './accountReducer'
import a2aMappingReducer from './a2aMappingReducer'
import titleReducer from './titleReducer'
import userRoleReducer from './userRoleReducer'
import customerReducer from './customerReducer'
import reportReducer from './reportReducer'
import invoiceReducer from './invoiceReducer'
import appBillingReducer from './appBillingReducer'

const rootReducer = combineReducers({
    app: appReducer,
    navBar: navBarReducer,
    project: projectReducer,
    vehicle: vehicleReducer,
    tenant: tenantReducer,
    auth: authReducer,
    users: usersReducer,
    timesheet: timesheetReducer,
    employee: employeeReducer,
    accounting: accountReducer,
    a2aMapping: a2aMappingReducer,
    title: titleReducer,
    userRoles: userRoleReducer,
    customer: customerReducer,
    report : reportReducer,
    invoice: invoiceReducer,
    appBilling: appBillingReducer,
});

export default rootReducer