import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tabs from 'react-bootstrap/cjs/Tabs';
import Tab from 'react-bootstrap/cjs/Tab';
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'react-bootstrap/Image';
import File from 'react-bootstrap/cjs/FormFile';
import Label from 'react-bootstrap/cjs/FormLabel';
import Modal from '../../../layout/form/Modal';
import '../settingMenu.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../../../layout/form/Button';
import TextField from '../../../layout/form/TextField';
import User from '../../../../images/user.png';
import ButtonGroup from '../../../layout/form/ButtonGroup'
import common from '../../../common';
import SelectField from '../../../layout/form/SelectField';
import Table from '../../../layout/form/Table';
import PhoneNumberField from '../../../layout/form/PhoneNumberField';

const customStyles = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 32,
      marginBottom: 0,
      marginTop: -20
    })
  };

const convertBase64 = (file) =>{
    return new Promise((resolve,reject)=>{
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () =>{
            resolve(fileReader.result)
        }

        fileReader.onerror = (error) =>{
            reject(error)
        }
    })
}

export class AddNewUser extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            status: '',
            userId:'',
            firstName:'',
            phoneNumber:'',
            email:'',
            lastName:'',
            userRole:'general',
            notes:'',
            logo:'',
            initial: true,
            userRoleList:[],
            tempUserRoleList:[],
            isDeleteUserRole: false,
            employee: 0
        };

      };

    static getDerivedStateFromProps(prevProps,prevState) {
        const { selectedUser, isUpdate, selectedRow, objectStatusList,isNewUser,selectedEmployee } = prevProps;
        if(selectedUser && selectedUser.id && prevState.initial && isUpdate && selectedUser.id === selectedRow.id){
            var itemList = []
            
            if(selectedUser.userRoles.length !== 0){
                for (let i = 0; i < selectedUser.userRoles.length; i++) {
                    const element = selectedUser.userRoles[i];
                    const newRow = {
                        roleId:  element.roleId,
                        id: element.id,
                        operation: "U"
                    }
                    itemList.push(newRow)
                }
            }
            return{
                status:selectedUser.status ,
                userId:selectedUser.userId,
                firstName:selectedUser.firstName,
                phoneNumber:selectedUser.mobileNo,
                email:selectedUser.email,
                lastName:selectedUser.lastName,
                userRole:selectedUser.userRole,
                notes:selectedUser.notes,
                logo:selectedUser.logo,
                initial:false,
                userRoleList: itemList
            }
        }
        else if(objectStatusList && objectStatusList.length && objectStatusList.length!==0 && prevState.initial && selectedEmployee && selectedEmployee.id){
            const defaultStatus = objectStatusList.filter(data => (data.default === true))
            return {
                status: defaultStatus[0].id,
                initial: false,
                employee: selectedEmployee.id,
                firstName: selectedEmployee.firstName,
                phoneNumber: selectedEmployee.mobile,
                email: selectedEmployee.email,
                lastName: selectedEmployee.lastName,
            }
        }
        else if(objectStatusList && objectStatusList.length && objectStatusList.length!==0 && isNewUser && prevState.initial){
            const defaultStatus = objectStatusList.filter(data => (data.default === true))
            return {
                status:defaultStatus[0].id,
                initial:false,
            }
        }
        return null
    }
           
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleChangeSelect = (value, id) => {
        this.setState({
          [id]: value
        })
    }

    mobileNumChange = (e) => {
        this.setState({
            phoneNumber:e
        })
    }

    handleChangeLogo = async (e) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        this.setState({logo: base64})
    }

    addNewRow = () => {
        var { userRoleList } = this.state;
        var newRow = {
            roleId:0,
            operation: "C",
            id: 0
        }
        userRoleList.push(newRow)
        this.setState({userRoleList})
    }

    handleRole = (id,rowData) => {
        const {userRoleList} = this.state;
        const newRow = {
            roleId: id,
            id: rowData.original.id,
            operation: rowData.original.id > 0 ? "U" : "C"
        }
        userRoleList[rowData.index] = newRow
        this.setState({
            userRoleList
        })
    }

    handleDelete = (rowData) => {
        var { userRoleList } = this.state;
        const newRow = {
            roleId: rowData.original.roleId,
            id: rowData.original.id > 0 ? rowData.original.id : null,
            operation: "D"
        }
        userRoleList[rowData.index] = newRow
        this.setState({
            userRoleList, 
            isDeleteUserRole:true,
        })
    }

    componentDidUpdate(prevProps, prevState){
        const { 
            userRoleList, 
            isDeleteUserRole, 
            tempUserRoleList,
        } = this.state;
        if(isDeleteUserRole){
            const list = userRoleList.filter(data => (data.operation === "U" || data.operation === "C"))
            const tempItemList = userRoleList.filter(data => (data.id !== null ))
            this.setState({
                userRoleList: list, 
                isDeleteUserRole: false, 
                tempUserRoleList: tempUserRoleList.concat(tempItemList)
            })
        }
    }
    
  
    render() {
        const {
            isAddNewUsersModalOpen, 
            handleModalClose,
            handleSaveAddNew,
            objectStatusList,
            selectedUser,
            isUpdate,
            selectedRow,
            roleList,
            modifyUser
        } = this.props;

        const columns = [{
            Header: 'Role',
            accessor: 'roleId',
            Cell:(rowData) =>(
                <div>
                    <SelectField
                        controlId="resource"
                        onChange={(e)=>this.handleRole(e,rowData.cell.row)}
                        options={roleList && roleList.length ? roleList : []}
                        style={customStyles}
                        selectedValue={rowData.cell.row.original.roleId}
                        disabled={!modifyUser}
                    />
                </div>
              ),    
          },{
            Header:'Action',
            width:'8%',
            Cell:((rowData)=>(
                <div>
                    {<span style={{display:'flex',justifyContent:'center'}}>
                        <span onClick={modifyUser ? ()=>this.handleDelete(rowData.cell.row) : ''}><ui5-icon class="samples-margin" name="delete" id={modifyUser ? "table-icon": "table-icon-disabled"}></ui5-icon></span>
                    </span>}
                </div>
            )),
            accessor:'action'
          }]

        return (
            <div>
                {((isUpdate && isUpdate && selectedUser.id === selectedRow.id) || !isUpdate) &&<Modal
                    show ={isAddNewUsersModalOpen} 
                    onHide={handleModalClose}
                    title="Users Details"
                    body={
                        <div>
                            <Tabs defaultActiveKey="basic" id="masterDataMappingTab" style={{fontSize:'14px'}}>
                                <Tab 
                                    eventKey="basic" 
                                    title={<span><ui5-icon class="samples-margin" name="account" id="tab-icon"></ui5-icon> Basic Details</span>}>
                                    <form style={{marginTop:'20px'}}>
                                        <div className="row">
                                            <div className="col-sm">
                                                <TextField
                                                    text="User Id"
                                                    controlId="userId"
                                                    placeholder="User Id"
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    defaultValue={this.state.userId}
                                                    maxLength={common.maxLengths.userId}
                                                    disabled
                                                />
                                                <TextField
                                                    text="First Name"
                                                    controlId="firstName"
                                                    placeholder="First Name"
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    defaultValue={this.state.firstName}
                                                    required
                                                    maxLength={common.maxLengths.userFirstName}
                                                />
                                                {/* <TextField
                                                    text="Phone Number"
                                                    controlId="phoneNumber"
                                                    placeholder="Phone Number"
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    defaultValue={this.state.phoneNumber}
                                                    maxLength={common.maxLengths.userMobile}
                                                    required
                                                /> */}
                                                <PhoneNumberField
                                                    text="Phone Number"
                                                    controlId="phoneNumber"
                                                    type="text"
                                                    // onBlur={this.mobileNumChange}
                                                    hint="Please enter valid phone number (eg:- +xxxxxxxxxx)"
                                                    // required={this.state.isMobileValidate}
                                                    onChange={this.mobileNumChange}
                                                    maxLength={common.maxLengths.userMobile}
                                                    value={this.state.phoneNumber}
                                                    // required
                                                />
                                                <Label>Avatar</Label>
                                                <div className="userPhoto">
                                                    <Image className="imageView" src={this.state.logo ? this.state.logo : User} />
                                                    <File id="userPhotoUpload" hidden ref={photoUpload => this.photoUpload = photoUpload} onChange={this.handleChangeLogo}/>
                                                        <div style={{display:'flex', justifyContent:'center'}}>
                                                            <Button 
                                                                variant="outline-primary" 
                                                                text ='Change'
                                                                size='sm'
                                                                onClick={() => this.photoUpload.click()}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <TextField
                                                        text="Email Address"
                                                        controlId="email"
                                                        placeholder="Email Address"
                                                        type="email"
                                                        onChange={this.handleChange}
                                                        defaultValue={this.state.email}
                                                        required
                                                        disabled={isUpdate}
                                                        maxLength={common.maxLengths.userEmail}
                                                    />
                                                    <TextField
                                                        text="Last Name"
                                                        controlId="lastName"
                                                        placeholder="Last Name"
                                                        type="text"
                                                        onChange={this.handleChange}
                                                        defaultValue={this.state.lastName}
                                                        required
                                                        maxLength={common.maxLengths.userLastName}
                                                    />
                                                    {/* <TextField
                                                        text="User Role"
                                                        controlId="userRole"
                                                        as='select'
                                                        onChange={this.handleChange}
                                                        options= {common.userRole}
                                                    /> */}
                                                    {/* <SelectField
                                                        text="User Role"
                                                        controlId="userRole"
                                                        onChange={(e)=>this.handleChangeSelect(e,'userRole')}
                                                        options= {common.userRole}
                                                        selectedValue={this.state.userRole}
                                                        isClearable
                                                        required
                                                    /> */}
                                                    <SelectField
                                                        text="Status"
                                                        controlId="status"
                                                        onChange={(e)=>this.handleChangeSelect(e,'status')}
                                                        options={objectStatusList}
                                                        selectedValue={this.state.status}
                                                        isClearable
                                                        required
                                                        disabled={!isUpdate}
                                                    />
                                                    <TextField
                                                        text="Notes"
                                                        controlId="notes"
                                                        placeholder="Notes"
                                                        type="text"
                                                        as="textarea" 
                                                        rows={5}
                                                        onChange={this.handleChange}
                                                        defaultValue={this.state.notes}
                                                    />   
                                                </div>
                                            </div>
                                    </form>
                                </Tab>
                                <Tab 
                                    eventKey="roles" 
                                    title={<span><ui5-icon class="samples-margin" name="role" id="tab-icon"></ui5-icon> Roles</span>}>
                                    <div className="profile-content" style={{minHeight:'calc(100vh - 200px)'}}>
                                        <div className="page_header">
                                            <div className="page_header-title">
                                                <span>User roles</span>
                                            </div>
                                            <div style={{marginBottom:'10px'}}>
                                                <Table
                                                    columns={columns}
                                                    data={this.state.userRoleList}
                                                />
                                                <div className={modifyUser ? "add-icon" : "add-icon-disabled"}>
                                                    <ui5-icon class="samples-margin" name="sys-add" id="add-activity" onClick={modifyUser ? () => this.addNewRow() : ''}></ui5-icon>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    }
                    footer={
                        <div>
                            <ButtonGroup
                                primaryBtnClick={handleModalClose}
                                primaryBtnVariant="outline-primary"
                                // primaryBtnText='Cancel'
                                // primaryBtnIcon='decline'
                                primaryBtnText='Go Back'
                                primaryBtnIcon='undo'
                                secondaryBtnClick={() => handleSaveAddNew(this.state)}
                                secondaryBtnText='Save'
                                secondaryBtnVariant="outline-primary" 
                                secondaryBtnIcon='save'
                                secondaryBtnDisabled = {!modifyUser}
                            />
                        </div>
                    }
                />}
        </div>
        )
    }
}

AddNewUser.propTypes = {
    isAddNewUsersModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    handleSaveAddNew: PropTypes.any,
    objectStatusList: PropTypes.any,
    selectedUser: PropTypes.any,
    isUpdate: PropTypes.any
}

export default AddNewUser;