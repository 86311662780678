import { createSelector } from 'reselect';

const selectProjectDomain = state => state.project;

const makeSelectProjectPopupOpen = () =>
  createSelector(
    selectProjectDomain,
    projectState =>
      projectState ? projectState.get('isProjectModalOpen') : false
  );

const makeSelectProjectList = () =>
  createSelector(
    selectProjectDomain,
    projectState =>
      projectState ? projectState.get('projectList') : []
  );

const makeSelectProjectListLoading = () =>
  createSelector(
    selectProjectDomain,
    projectState =>
      projectState ? projectState.get('isProjectListLoading') : false
  );

const makeSelectProjectListError = () =>
  createSelector(
    selectProjectDomain,
    projectState =>
      projectState ? projectState.get('projectListError') : null
  );

const makeSelectProjectSuccess = () =>
  createSelector(
    selectProjectDomain,
    projectState =>
      projectState ? projectState.get('projectSuccess') : null
  );

const makeSelectSelectedProjectSuccess = () =>
  createSelector(
    selectProjectDomain,
    projectState =>
      projectState ? projectState.get('selectedProject') : ''
  );

const makeSelectProjectActivitySuccess = () =>
  createSelector(
    selectProjectDomain,
    projectState =>
      projectState ? projectState.get('projectActivitySuccess') : null
  );

const makeSelectProjectAssignmentSuccess = () =>
  createSelector(
    selectProjectDomain,
    projectState =>
      projectState ? projectState.get('projectAssignmentSuccess') : null
  );

const makeSelectSummarisedProjectListSuccess = () =>
  createSelector(
    selectProjectDomain,
    projectState =>
      projectState ? projectState.get('summarisedProjectList') : []
  );

const makeSelectProjectAllocationTypeListSuccess = () =>
  createSelector(
    selectProjectDomain,
    projectState =>
      projectState ? projectState.get('projectAllocationTypeList') : []
  );

const makeSelectProjectTypeListSuccess = () =>
  createSelector(
    selectProjectDomain,
    projectState =>
      projectState ? projectState.get('projectTypeList') : []
  );

const makeSelectProjectBillingTypeListSuccess = () =>
  createSelector(
    selectProjectDomain,
    projectState =>
      projectState ? projectState.get('projectBillingTypeList') : []
  );

export {
  makeSelectProjectPopupOpen,
  makeSelectProjectList,
  makeSelectProjectListLoading,
  makeSelectProjectListError,
  makeSelectProjectSuccess,
  makeSelectSelectedProjectSuccess,
  makeSelectProjectActivitySuccess,
  makeSelectProjectAssignmentSuccess,
  makeSelectSummarisedProjectListSuccess,
  makeSelectProjectAllocationTypeListSuccess,
  makeSelectProjectTypeListSuccess,
  makeSelectProjectBillingTypeListSuccess
}
